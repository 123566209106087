<template>
  <div>
    <v-select
      v-model="company.subcompanyId"
      :items="subcompanies"
      item-text="name"
      item-value="id"
      dense outlined hide-details
    ></v-select>
  </div>
</template>

<script>
export default {
  props: ['company'],
  computed : {
    subcompanies : {
      get:function(){ return this.$store.getters.GET_SUBCOMPANIES },
      set:function(value){ return this.$store.commit('SET_SUBCOMPANIES',{subcompanies:value}) }
    },
  },
}
</script>

<style>

</style>