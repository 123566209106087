<template>
  <div>
    <Widgets @create="onCreate" @reload="reloading"/>
    <v-row>
      <v-col>
        <v-row>
          <v-col class="text-center">
            <h3>{{subcompanies[0] ? subcompanies[0].name : ''}}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <Companies :subcompany="1" ref="argentina" />
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-row>
          <v-col class="text-center">
            <h3>{{subcompanies[1] ? subcompanies[1].name : ''}}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <Transviva :subcompany="2" ref="transviva"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Widgets from './Widgets.vue'
import Companies from './Companies.vue'
import Transviva from './transviva/Transviva.vue'
export default {
  components: { Widgets, Companies, Transviva },
  computed:{
    subcompanies : {
      get:function(){ return this.$store.getters.GET_SUBCOMPANIES },
      set:function(value){ return this.$store.commit('SET_SUBCOMPANIES',{subcompanies:value}) }
    },
  },
  mounted(){
    this.loadBase();
  },
  methods:{
    loadBase(){
      this.$store.dispatch('LOAD_BASE_COMPANIES')
    },
    onCreate(subcompanyId){
      switch(subcompanyId.subcompany){
        case 1: this.$refs.argentina.loadCompanies(); break;
        case 2: this.$refs.transviva.loadCompanies(); break;
      }
    },
    reloading(){
      this.$refs.argentina.loadCompanies();
      this.$refs.transviva.loadCompanies();
    }
  }
}
</script>

<style>

</style>