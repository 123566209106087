<template>
  <div>
    <v-row>
      <v-col cols="3">
        Sum
      </v-col>
      <v-col cols="9">
        <v-row>
          <v-col class="pb-0">
            <v-text-field
              v-model="card.sum_vat"
              type="number"
              @keyup="changeSum()"
              dense outlined hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0">
            <v-text-field
              v-model="card.sum_en"
              dense outlined hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="card.sum_lt"
              dense outlined hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import numWords from './numWords';

export default {
  props : ['card'],
  methods : {
    changeSum (){
      this.card.sum_lt=numWords.numLT( Number(this.card.sum_vat).toFixed(2));
      this.card.sum_en=numWords.numEN( Number(this.card.sum_vat).toFixed(2));
    },
    hundToEN(input, addAND, EN){
      if(input[0] == 0){
        input = input.slice(1);
      }
      let str = '';
      if(input.length == 3){
        str += EN[input[0]] + 'hundred ';
        input = input.slice(1);
      }
      if(input[0] == 0){
        input = input.slice(1);
      }
      if(input.length == 2){
        if(input[0] == 1){
          str += EN[input];
          return str;
        }
        str += EN[input[0] + '0'];
        input = input.slice(1);
        if(input[0] == 0){
          return str;
        }
        return str + EN[input[0]];
      }
      str += (addAND ? 'and ' + EN[input] : EN[input]);	
      return str;
    },
    hundToLT(input, LT){
      if(input[0] == 0){
        input = input.slice(1);
      }
      let str = '';
      if(input.length == 3){
        if(input[0] == 1){
          str += 'šimtas ';
        }else{
          str += LT[input[0]] + 'šimtai ';
        }
        input = input.slice(1);	
      }
      if(input[0] == 0){
        input = input.slice(1);
      }
      if(input.length == 2){
        if(input[0] == 1){
          str += LT[input];
          return str;
        }
        str += LT[input[0] + '0'];
        input = input.slice(1);
        if(input[0] == 0){
          return str;
        }
        return str + LT[input[0]];
      }
      str += LT[input];	
      return str;
    },
    numLT(input){
      let LT = {		
        0 : "nulis ", 1 : "vienas ", 2 : "du ", 3 : "trys ",
        4 : "keturi ", 5 : "penki ", 6 : "šeši ", 7 : "septyni ",
        8 : "aštuoni ", 9 : "devyni ", 10 : "dešimt ", 11 : "vienuolika ",
        12 : "dvylika ", 13 : "trylika ", 14 : "keturiolika ", 15 : "penkiolika ",
        16 : "šešiolika ", 17 : "septyniolika ", 18 : "aštuoniolika ", 19 : "devyniolika ",
        20 : "dvidešimt ", 30 : "trisdešimt ", 40 : "keturiasdešimt ", 50 : "penkiasdešimt ",
        60 : "šešiasdešimt ", 70 : "septyniasdešimt ", 80 : "aštuoniasdešimt ", 90 : "devyniasdešimt "
      }
      input += '';
      let parts = input.split('.'); 
      let razryad = [];
      if(parts[0].length % 3 > 0){
        razryad.push(parts[0].slice(0, parts[0].length % 3));
        parts[0] = parts[0].slice(parts[0].length % 3)
      }else{
        razryad.push(parts[0].slice(0, 3));
        parts[0] = parts[0].slice(3);
      }
      for(; parts[0].length > 0; parts[0] = parts[0].slice(3)){
        razryad.push(parts[0].slice(0, 3));
      }
      let word = '';
      for(let i = 0; i < razryad.length; i++){
        if(razryad.length - i == 4){
          word += this.hundToLT(razryad[i], LT) + 'milijardų ';
        }
        if(razryad.length - i == 3){
          word += this.hundToLT(razryad[i], LT) + 'milijonų ';
        }
        if(razryad.length - i == 2){
          word += this.hundToLT(razryad[i], LT) + 'tūkstančiai ';
        }
        if(razryad.length - i == 1){
          word += this.hundToLT(razryad[i], LT);
        }
      }

      if(parts[1]){
        word += 'kablelis ';
        parts[1] = parts[1].split('');
        parts[1].forEach(el => {
          word += LT[el];
        })
      }
      return word.slice(0,-1);
    },
    numEN(input){
      let EN = {
        0: "zero ", 1: "one ", 2:	"two ", 3: "three ",
        4: "four ", 5: "five ", 6: "six ", 7: "seven ",
        8:	"eight ", 9: "nine ", 10: "ten ", 11: "eleven ",
        12: "twelve ", 13: "thirteen ", 14: "fourteen ", 15: "fifteen ",
        16: "sixteen ", 17: "seventeen ", 18: "eighteen ", 19: "nineteen ",
        20: "twenty ", 30: "thirty ", 40: "forty ", 50: "fifty ", 
        60: "sixty ", 70: "seventy ", 80: "eighty", 90: "ninety "
      }

      input += '';
      let parts = input.split('.'); 
      let razryad = [];
      if(parts[0].length % 3 > 0){
        razryad.push(parts[0].slice(0, parts[0].length % 3));
        parts[0] = parts[0].slice(parts[0].length % 3)
      }else{
        razryad.push(parts[0].slice(0, 3));
        parts[0] = parts[0].slice(3);
      }
      for(; parts[0].length > 0; parts[0] = parts[0].slice(3)){
        razryad.push(parts[0].slice(0, 3));
      }
      let word = '';
      for(let i = 0; i < razryad.length; i++){
        if(razryad.length - i == 4){
          word += this.hundToEN(razryad[i], false, EN) + 'billion ';
        }
        if(razryad.length - i == 3){
          word += this.hundToEN(razryad[i], false, EN) + 'million ';
        }
        if(razryad.length - i == 2){
          word += this.hundToEN(razryad[i], false, EN) + 'thousand ';
        }
        if(razryad.length - i == 1){
          word += this.hundToEN(razryad[i], true, EN);
        }
      }
      if(parts[1]){
        word += 'point ';
        parts[1] = parts[1].split('');
        parts[1].forEach(el => {
          word += EN[el];
        })
      }
      return word.slice(0,-1);

    }
  }
}
</script>

<style>

</style>