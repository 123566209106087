<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col align="end">
          <v-btn
            small
            @click="addCompany()"
            color="green accent-3">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <v-col align="center">
          <h2> Companies </h2>
        </v-col>
        <v-col align="start">
          <v-btn
            small
            @click="reloadCompanies()"
            color="cyan accent-3">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <CreateComponent :company="company" @create="onCreate"/>
  </div>
</template>

<script>
import CreateComponent from './create/CreateComponent.vue'
export default {
  name : 'Widgets',
  components:{
    CreateComponent
  },
  computed:{
    company:{ 
      get:function(){ return this.$store.getters.GET_COMPANY },
      set:function(value){ return this.$store.commit('SET_COMPANY',{company:value}) }
    },
    modal_company:{ 
      get:function(){ return this.$store.getters.GET_MODAL_COMPANY },
      set:function(value){ return this.$store.commit('SET_MODAL_COMPANY',{modal_company:value}) }
    },
  },
  methods:{
    addCompany(){
      this.modal_company.create=true;
    },
    reloadCompanies(){
      this.$emit('reload')
    },
    onCreate(subcompanyId){
      this.$emit('create', { subcompany: subcompanyId.subcompany})
    }
  }
}
</script>

<style>

</style>