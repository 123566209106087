<template>
  <div>
    <v-simple-table dense class="bb">
      <template v-slot:default>
        <tbody>
          <td colspan="15"></td>
          <td class="ell brdr pl-2 pr-2" style="min-width:80px; width:80px; max-width:80px;">{{calc.calcSumCardAmonut(card)}}</td>
          <td class="ell brdr pl-2 pr-2" style="min-width:80px; width:80px; max-width:80px;">{{calc.calcSumAmonut(card)}}</td>
          <td class="ell brdr pl-2 pr-2" style="min-width:80px; width:80px; max-width:80px;">{{calc.calcSumAmonutVAT(card)}}</td>
        </tbody>
        <tbody>
          <tr style="background-color:#CFD8DC">
            <th class="ell">Date</th>
            <th class="ell">Kortelės numeris</th>
            <th class="ell">Kvito Nr.</th>
            <th class="ell">Degalinė</th>
            <th class="ell">Šalis</th>
            <th class="ell">Produktas</th>
            <th class="ell">Kiekis</th>
            <th class="ell">Kaina</th>
            <th class="ell">Kiekis</th>
            <th class="ell">PVM</th>
            <th class="ell">NET</th>
            <th class="ell">Nuolaida</th>
            <th class="ell" style="min-width:80px; width:80px; max-width:80px;">Suma (su nuolaida)</th>
            <th colspan="2">%</th>
            <th class="ell" style="min-width:80px; width:80px; max-width:80px;">KORTELĖS MOKESTIS</th>
            <th class="ell" style="min-width:80px; width:80px; max-width:80px;">Mokėtina suma be PVM</th>
            <th class="ell" style="min-width:80px; width:80px; max-width:80px;">Mokėtina suma su PVM</th>
          </tr>
        </tbody>
        <tbody>
          <tr
            v-for="(data,indx) in card.data" :key="indx"
          >
            <td class="brdr" style="min-width:150px; width:150px; max-width:150px;">
              {{data.datetime}}
            </td>
            <td class="brdr" style="min-width:180px; width:180px; max-width:180px;">
              {{data.card_code}}
            </td>
            <td class=" brdr" style="min-width:100px; width:100px; max-width:100px;">
              {{data.receipt}}
            </td>
            <td class="ell brdr" style="min-width:120px; width:120px; max-width:120px;">
              {{data.petrol}}
            </td>
            <td class=" brdr" style="min-width:50px; width:50px; max-width:50px;">
              {{data.country}}
            </td>
            <td class="ell brdr" style="min-width:120px; width:120px; max-width:120px;">
              {{data.product_name}}
            </td>
            <td class=" brdr" style="min-width:80px; width:80px; max-width:80px;">
              {{data.count}}
            </td>
            <td class=" brdr" style="min-width:80px; width:80px; max-width:80px;">
              {{data.price}}
            </td>
            <td class=" brdr" style="min-width:80px; width:80px; max-width:80px;">
              {{data.price_count}}
            </td>
            <td class=" brdr" style="min-width:80px; width:80px; max-width:80px;">
              {{data.vat}}
            </td>
            <td class=" brdr" style="min-width:80px; width:80px; max-width:80px;">
              {{data.net}}
            </td>
            <td class=" brdr" style="min-width:80px; width:80px; max-width:80px;">
              {{data.discount}}
            </td>
            <td class=" brdr" style="min-width:60px; width:60px; max-width:60px;">
              {{data.discount_price}}
            </td>
            <td class=" brdr" style="min-width:60px; width:60px; max-width:60px; padding:0">
              <v-text-field
                v-model="data.coef"
                @keyup="changeOverprice(indx)"
                dense outlined hide-details
              ></v-text-field>
            </td>
            <td class=" brdr" style="min-width:60px; width:60px; max-width:60px;">
              <v-checkbox
                v-model="data.overprice"
                color="primary"
                @change="changeOverprice(indx)"
                hide-details dense
              ></v-checkbox>
            </td>
            <td class="ell brdr" style="min-width:50px; width:50px; max-width:50px;">
              {{(data.card_amount)}}
            </td>
            <td class="ell brdr" style="min-width:80px; width:80px; max-width:80px;">
              {{data.amount}}
            </td>
            <td class="ell brdr" style="min-width:80px; width:80px; max-width:80px;">
              {{data.amount_vat}}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import calc from './calc';
export default {
  props : ['card'],
  computed : {
    calc : ()=>{
      return calc;
    },
    colors:{ 
      get:function(){ return this.$store.getters.GET_COLORS }
    },
    companies : {
      get:function(){ return this.$store.getters.GET_COMPANIES },
      set:function(value){ return this.$store.commit('SET_COMPANIES',{companies:value}) }
    },
    coefChange : function(){
      return this.card.coef;
    }
  },
  watch : {
    coefChange(val){
      calc.changeCalc(this.card);
    }
  },
  methods : {
    getCompany(id){
      for(let i=0; i<this.companies.length; i++){
        if(this.companies[i].code == id){
          return this.companies[i].name;
          break;
        }
      }
    },
    changeOverprice(index){
      calc.changeOverprice(this.card.data[index])
    }
    
  }
}
</script>

<style>

</style>