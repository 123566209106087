<template>
  <div>
    <v-row>
      <v-col cols="3">
        Document number
      </v-col>
      <v-col>
        <v-text-field
          v-model="card.preview"
          dense outlined hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    
  </div>
</template>

<script>
export default {
  props : ['card']
}
</script>

<style>

</style>