<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col sm="12" md="6" xs="12">
          <v-card>
            <v-card-title class="headline primary" style="color:white;">
              LogIn
            </v-card-title>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Login"
                    type="text"
                    v-model="user.username"
                    dense hide-details="false"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Password"
                    type="password"
                    v-model="user.password"
                    dense hide-details="false"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn
                  style="margin: 6px 12px 12px 0px;" 
                  color="success"
                  @click="login()"
                >
                  <v-icon style="margin-right: 8px;">mdi-login</v-icon>
                  LogIn
                </v-btn>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Login',
  
  data(){
    return{
      user:{
        username:'',
        password:''
      }
    }
  },
  methods:{
    login()
    {
      this.$store.dispatch('LOGIN',{user:this.user, vm: this })   
    }
  }
}
</script>

<style>

</style>