<template>
  <div>
    <v-select
      v-model="user.role"
      :items="roles"
      item-text="name"
      item-value="role"
      label="Role"
      dense outlined hide-details
    ></v-select>
  </div>
</template>

<script>
export default {
  name : 'Role',
  props : ['user'],
  data(){
    return {
      roles : [
        {
          role:'admin',
          name:'Администратор'
        },
        {
          role:'agent',
          name:'Агент'
        },
        {
          role:'manager',
          name:'Менеджер'
        },
      ]
    }
  }
}
</script>

<style>

</style>