<template>
  <div>
    <v-dialog 
      max-width="1200"
      v-model="modal_subcompany.create"
      scrollable
    >
      <v-card>
        <v-card-title 
          class="headline"
          style="background-color: #4caf50; color:white;"
        >
          Add SubCompany
        </v-card-title>
        <v-card-text style="padding:0px;">
          <v-container>
            <v-row>
              <v-col>
                <SubCompany :subcompany="subcompany" />
              </v-col>
            </v-row>
          </v-container>
          
        </v-card-text>
        
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            @click="modal_subcompany.create=false"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="save_new_subcompany()"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SubCompany from '../layout/SubCompany.vue'
export default {
  name : 'CreateSubCompnay',
  components : { SubCompany },
  computed : {
    subcompany:{ 
      get:function(){ return this.$store.getters.GET_SUBCOMPANY },
      set:function(value){ return this.$store.commit('SET_SUBCOMPANY',{subcompany:value}) }
    },
    modal_subcompany:{ 
      get:function(){ return this.$store.getters.GET_MODAL_SUBCOMPANY },
      set:function(value){ return this.$store.commit('SET_MODAL_SUBCOMPANY',{modal_subcompany:value}) }
    },
  },
  methods : {
    save_new_subcompany(){
      let errors = this.check_new_subcompany();
      if( errors.length==0 ){
        this.$store.dispatch('CREATE_SUBCOMPANY');
      } else {
        for(let i=0; i<errors.length; i++){
          this.$toast.error({title:"Создание Компании", message: errors[i]})
        }
      }
    },
    check_new_subcompany(){
      let errors = [];
      if(!this.subcompany.name){ errors.push("Наименование пусто!") }
      // if(!this.subcompany.address){ errors.push("Адрес пусто!") }
      // if(!this.subcompany.tin){ errors.push("ИНН пусто!") }
      // if(!this.subcompany.requisites){ errors.push("Реквизиты пусто!") }
      // if(!this.subcompany.comment){ errors.push("Комментарии пусто!") }
      // if(!this.subcompany.director){ errors.push("Директор пусто!") }
      return errors;
    }
  }
}
</script>

<style>

</style>