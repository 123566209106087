<template>
  <div>
      <v-row>
        <v-col style="padding:0px;">
          <v-btn-toggle
            tile
            color="deep-purple accent-3"
            group
          >
            <v-btn v-for="(widget, index) in widgets" :key="index"
              small
              value="left" 
              style="margin-left:0px;"
              :to="{ name: widget.route}"
            >
              {{widget.name}}
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    
  </div>
</template>

<script>
export default {
  data: ()=>({
    widgets:[
      {
        name : 'Main',
        route : 'EditBookMain'
      },
    ]
  }),
}
</script>

<style>

</style>