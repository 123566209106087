<template>
  <div>
    <v-row>
      <v-col>
        Preview : 
        {{numbering.prefix + 
          (numbering.index.length>0 || numbering.count.length>0 ? numbering.split : '' ) + calcCount(numbering.index) + 
          (numbering.suffix.length>0 ? numbering.split : '' ) + numbering.suffix
        }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props : ['numbering'],
  data: ()=>({
    preview : null
  }),
  methods : {
    calcCount(index){
      let count = Number(this.numbering.count)
      if(count>0){
        let currentSymbols = this.numbering.index.length;
        if(currentSymbols<count){
          let diff = count - currentSymbols;
          let temp='';
          for (let i = 0; i < diff; i++) {
            temp += '0';
          }
          index = temp + this.numbering.index
        }
      }
      return index;
    }
  }
}
</script>

<style>

</style>