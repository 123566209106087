<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-col class="pb-0">
            <Names :company="company" />    
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <Code :company="company" />
          </v-col>
          <v-col>
            <Coef :company="company" />   
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <Subcompany :company="company" />   
          </v-col>
        </v-row>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col>
        <Address :company="company" />
      </v-col>
    </v-row>
    <v-row>
      <v-col style="padding:0px;">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <Email :company="company" />
        <PaymentPeriod :company="company" />
      </v-col>
      <v-divider vertical></v-divider>
      <v-col style="padding-top:0px;">
        <Requisites :company="company" style="margin-top : 12px;" />
      </v-col>
    </v-row>
    <v-row>
      <v-col style="padding:0px;">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <Comment :company="company" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Address from './Address.vue'
import Contact from './Contact.vue'
import Names from './Names.vue'
import Comment from './Comment.vue'
import Requisites from './Requisites.vue'
import Code from './Code.vue'
import Coef from './Coef.vue'
import Email from './Email.vue'
import PaymentPeriod from './PaymentPeriod.vue'
import Subcompany from './Subcompany.vue'
export default {
  components: { Names, Address, Contact, Comment, Requisites, Code, Coef, Email, PaymentPeriod, Subcompany },
  name : 'Company',
  props : ['company'],

}
</script>

<style>

</style>