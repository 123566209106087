<template>
  <div>
    <v-row>
      <v-col>
        <v-text-field
          v-model="numbering.index"
          label="Index"
          outlined dense hide-details
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props : ['numbering'],
  data: ()=>({
    
  }),
}
</script>

<style>

</style>