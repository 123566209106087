<template>
  <div>
    <v-simple-table dense>
      <template v-slot:default>
        <tbody>
          <tr>
            <td class="pa-0">
              <v-text-field
                v-model="searchCompany.id"
                dense outlined hide-details
                disabled
              ></v-text-field>
            </td>
            <td class="pa-0">
              <v-text-field
                v-model="searchCompany.code"
                dense outlined hide-details
              ></v-text-field>
            </td>
            <td class="pa-0">
              <v-text-field
                v-model="searchCompany.coef"
                dense outlined hide-details
              ></v-text-field>
            </td>
            <td class="pa-0">
              <v-text-field
                v-model="searchCompany.name"
                dense outlined hide-details
              ></v-text-field>
            </td>
            <td class="pa-0">
              <v-text-field
                v-model="searchCompany.address"
                dense outlined hide-details
              ></v-text-field>
            </td>
            <td class="pa-0">
              <v-text-field
                v-model="searchCompany.requisites"
                dense outlined hide-details
              ></v-text-field>
            </td>
            <td class="pa-0">
              <v-text-field
                v-model="searchCompany.comment"
                dense outlined hide-details
              ></v-text-field>
            </td>
            <td class="pa-0">
              <v-text-field
                v-model="searchCompany.emails"
                dense outlined hide-details
              ></v-text-field>
            </td>
            <td class="pa-0" align="center">
              <v-btn
                class="ma-1"
                color="success"
                icon
                @click="doSearch()"
              >
                <v-icon>mdi-magnify-expand</v-icon>
              </v-btn>
              <v-btn
                class="ma-1"
                color="default"
                icon
                @click="clearSearch()"
              >
                <v-icon>mdi-close-box-outline</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr :style="{'background-color': colors.table}">
            <th class="text-left" style="color:white;">
              #
            </th>
            <th class="text-left" style="color:white;">
              Code
            </th>
            <th class="text-left" style="color:white;">
              Coef.
            </th>
            <th class="text-left" style="color:white;">
              Name
            </th>
            <th class="text-left" style="color:white;">
              Address
            </th>
            <th class="text-left" style="color:white;">
              Requisites
            </th>
            <th class="text-left" style="color:white;">
              Comments
            </th>
            <th class="text-left" style="color:white;">
              Emails
            </th>
            <th class="text-left" style="color:white;">
              Actions
            </th>
          </tr>
        </tbody>
        <tbody>
          <tr
            v-for="(company,index) in companies" :key="index"
          >
            <td class="brdr" style="max-width:50px; width:50px;">
              {{index+1}}
            </td>
            <td class="brdr" style="max-width:80px; width:80px; min-width:80px;">
              {{company.code}}
            </td>
            <td class="brdr" style="max-width:100px; width:100px;">
              {{company.coef}}
            </td>
            <td class="ell brdr" style="max-width:150px; width:100px;">
              {{company.name}}
            </td>
            <td class="ell brdr" style="max-width:100px; width:100px;">
              {{company.address}}
            </td>
            <td class="ell brdr" style="max-width:100px; width:100px;">
              {{company.requisites}}
            </td>
            <td class="ell brdr" style="max-width:80px; width:80px; min-width:80px;">
              {{company.comment}}
            </td>
            <td class="ell brdr" style="max-width:80px; width:80px;">
              {{company.emails}}
            </td>
            <td class="brdr" style="max-width:100px; width:100px; min-width:100px;">
              <v-row>
                <v-col  align="center" class="pa-0">
                  <v-btn
                    icon
                    color="warning"
                    @click="edit_company(index, company.id)"
                  >
                    <v-icon>mdi-circle-edit-outline</v-icon>
                  </v-btn>
                </v-col>
                <v-col  align="center" class="pa-0">
                  <v-btn
                    icon
                    color="error"
                    @click="delete_production(index, company.id)"
                  >
                    <v-icon>mdi-delete-circle-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr :style="{'background-color': colors.table}">
            <td colspan="100"></td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
    <EditCompany :company="editCompany" />
    <DeleteCompany :company="deleteCompany" />
  </div>
</template>

<script>
import EditCompany from '@/components/companies/edit/EditComponent.vue';
import DeleteCompany from '@/components/companies/delete/DeleteComponent.vue';
export default {
  name : 'CompaniesTable',
  props : ['companies', 'subcompany'],
  components:{EditCompany, DeleteCompany},
  data: ()=>({
    editCompany:{},
    deleteCompany:{},
  }),
  computed : {
    colors:{ 
      get:function(){ return this.$store.getters.GET_COLORS }
    },
    searchCompany:{ 
      get:function(){ return this.$store.getters.GET_SEARCHCOMPANY },
      set:function(value){ return this.$store.commit('SET_SEARCHCOMPANY',{searchCompany:value}) }
    },
  },
  methods:{
    format_date(_date){ return new Date(_date).toLocaleDateString("ru-RU"); },
    type(type){
      return type==0 ? 'Клиент' : 'Перевозчик';
    },
    edit_company(index, id){
      if( this.companies[index].id == id ){
        this.editCompany=Object.assign({}, this.companies[index]);
        this.$store.commit('SET_MODAL_COMPANY',{edit:true});
      }
      
    },
    delete_production(index, id){
      if( this.companies[index].id == id ){
        this.deleteCompany=Object.assign({}, this.companies[index]);
        this.$store.commit('SET_MODAL_COMPANY',{delete:true});
      }
    },
    doSearch(){
      this.loadCompanies();
    },
    loadCompanies(){
      this.$store.dispatch('LOAD_COMPANIES', {subcompany: this.subcompany});
    },
    clearSearch(){
      this.searchCompany={};
      this.loadCompanies();
    }
  }
}
</script>

<style scope>
  .ell{
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
  }
  .brdr{
    border-top: 0.25px solid #e0e0e0; 
    border-left: 0.25px solid #e0e0e0; 
    border-right: 0.25px solid #e0e0e0;
  }
</style>