<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-col style="padding-bottom:0px;">
            <FirstName :user="user" />
          </v-col>
        </v-row>
        <v-row>
          <v-col style="padding-bottom:0px;">
            <LastName :user="user" />
          </v-col>
        </v-row>
        <v-row>
          <v-col style="padding-bottom:0px;">
            <Username :user="user" />
          </v-col>
        </v-row>
        <v-row>
          <v-col style="padding-bottom:0px;">
            <Password :user="user" />
          </v-col>
        </v-row>
        <v-row>
          <v-col style="padding-bottom:0px;">
            <Role :user="user" />
          </v-col>
        </v-row>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col>
        <v-row>
          <v-col align="center">
            <v-icon size="80">mdi-card-account-details-outline</v-icon> 
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center" style="padding-bottom:0px;">
            <h3>User</h3>            
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div>First name: {{user.first_name}}</div>
            <div>Last name: {{user.last_name}}</div>
            <div>Login: {{user.username}}</div>
            <div>Password: {{user.password}}</div>
            <div>Role: {{user.role}}</div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    
    
    
  </div>
</template>

<script>
import FirstName from './FirstName.vue'
import LastName from './LastName.vue'
import Username from './Username.vue'
import Password from './Password.vue'
import Role from './Role.vue'
export default {
  props : ['user'],
  components: { FirstName, LastName, Username, Password, Role },

}
</script>

<style>

</style>