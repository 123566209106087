<template>
  <div>
    <div v-if="loading">Loading...</div>
    <v-simple-table dense v-if="!loading">
      <template v-slot:default>
        <tbody>
          <tr>
            <td class="pa-0">
              <v-text-field
                v-model="searchBook.id"
                dense outlined hide-details
              ></v-text-field>
            </td>
            <td class="pa-0">
              <table>
                <tr>
                  <td>
                    <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      clearable
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="searchBook.date_begin"
                          hide-details outlined dense clearable
                          readonly v-bind="attrs" v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="searchBook.date_begin"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </td>
                  <td>
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      clearable
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="searchBook.date_end"
                          hide-details outlined dense clearable
                          readonly v-bind="attrs" v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="searchBook.date_end"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </td>
                </tr>
              </table>
            </td>
            <td class="pa-0">
              <v-select
                v-model="searchBook.subcompany"
                :items="subcompanies"
                item-text="name"
                item-value="id"
                dense outlined hide-details
                clearable
              ></v-select>
            </td>
            <td class="pa-0">
              <v-text-field
                v-model="searchBook.comment"
                dense outlined hide-details
              ></v-text-field>
            </td>
            <td class="pa-0">
              <v-select
                v-model="searchBook.author"
                :items="baseUsers"
                :item-text="item => item.first_name +' '+ item.last_name"
                item-value="id"
                dense outlined hide-details
                clearable
              ></v-select>
            </td>
            <td class="pa-0" align="center">
              <v-btn
                class="ma-1"
                color="success"
                icon
                @click="doSearch()"
              >
                <v-icon>mdi-magnify-expand</v-icon>
              </v-btn>
              <v-btn
                class="ma-1"
                color="default"
                icon
                @click="clearSearch()"
              >
                <v-icon>mdi-close-box-outline</v-icon>
              </v-btn>
            </td>
          </tr>
          <tr :style="{'background-color': colors.table}">
            <th class="text-left" style="color:white;">
              #
            </th>
            <th class="text-left" style="color:white;">
              Date
            </th>
            <th class="text-left" style="color:white;">
              Sub Company
            </th>
            <th class="text-left" style="color:white;">
              Comment
            </th>
            <th class="text-left" style="color:white;">
              Manager
            </th>
            <th class="text-left" style="color:white;">
              Actions
            </th> 
          </tr>
          <tr
            v-for="(book,index) in books" :key="index"
          >
            <td class="brdr" style="max-width:75px; width:75px;">
              {{book.id}}
            </td>
            <td class="brdr"  style="max-width:275px; width:275px;" align="center">
              {{format_date(book.createdAt)}}
            </td>
            <td class="ell brdr" style="max-width:400px; width:400px;">
              {{getSubCompany(book.subcompany)}}
            </td>
            <td class="ell brdr">
              {{book.comment}}
            </td>
            <td class="ell brdr" style="max-width:300px; width:300px;">
              {{getUser(book.editor)}}
            </td>
            <td class="brdr" style="max-width:120px; width:120px;">
              <v-row>
                <v-col class="pa-0" align="center">
                  <v-btn
                    icon
                    color="warning"
                    @click="edit_book(index, book.id)"
                  >
                    <v-icon>mdi-circle-edit-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr :style="{'background-color': colors.table}">
            <td colspan="100"></td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
  </div>
</template>

<script>

export default {
  name : 'BooksTable',
  data: ()=>({
    editTransportation:{},
    menu1:false,
    menu2:false
  }),
  computed : {
    books:{ 
      get:function(){ return this.$store.getters.GET_BOOKS },
      set:function(value){ return this.$store.commit('SET_BOOKS',{books:value}) }
    },
    searchBook:{ 
      get:function(){ return this.$store.getters.GET_SEARCHBOOK },
      set:function(value){ return this.$store.commit('SET_SEARCHBOOK',{searchBook:value}) }
    },
    loading:{ 
      get:function(){ return this.$store.getters.GET_LOADING },
      set:function(value){ return this.$store.commit('SET_LOADING',{loading:value}) }
    },
    colors:{ 
      get:function(){ return this.$store.getters.GET_COLORS }
    },
    subcompanies : {
      get:function(){ return this.$store.getters.GET_SUBCOMPANIES },
      set:function(value){ return this.$store.commit('SET_SUBCOMPANIES',{subcompanies:value}) }
    },
    baseUsers : { 
      get:function(){ return this.$store.getters.GET_BASEUSERS },
      set:function(value){ return this.$store.commit('SET_BASEUSERS',{baseUsers:value}) }
    },
  },
  mounted(){
    //this.loadBooks();
  },
  methods:{
    format_date(_date){ return new Date(_date).toLocaleDateString("ru-RU"); },
    edit_book(index, id){
      if( this.books[index].id == id ){
        this.$store.commit('UNSET_BOOK')
        this.$router.push('/books/'+id+'/main')
      }
      
    },
    loadBooks(){
      this.$store.dispatch('LOAD_BOOKS');
    },
    getSubCompany(id){
      for(let i=0; i<this.subcompanies.length; i++){
        if(this.subcompanies[i].id == id){
          return this.subcompanies[i].name;
          break;
        }
      }
    },
    getUser(id){
      for (let i = 0; i < this.baseUsers.length; i++) {
        if(this.baseUsers[i].id == id){
          return this.baseUsers[i].first_name + ' ' + this.baseUsers[i].last_name
        }        
      }
    },
    doSearch(){
      this.loadBooks();
    },
    clearSearch(){
      this.searchBook={};
      this.loadBooks();
    }
  }
}
</script>

<style>

</style>