export default
{
  state: {
    
    book:{
      file:[],
      subcompany:null,
      type: null,
      comment:'',
      paydate: null,
      payment_period:10,
      month:(new Date().getMonth()-1)==0 ? 12 : (new Date().getMonth()-1)
    },
    preview:[],
    savedPreview:false,
    modal_book:{
      create:false,
      edit:false,
      delete:false,
      pdf:false,
      pdfWidgets:false,
    },
    books:[],
    loading:true,
    last_invoice : null,
    searchBook:{},
  },
  mutations:{
    SET_BOOK(state,payload){ state.book=payload.book; },
    UNSET_BOOK(state){ state.book={
      file:[],
      subcompany:null,
      type: null,
      comment:'',
      paydate: null,
      payment_period:10,
      month:(new Date().getMonth()-1)==0 ? 12 : (new Date().getMonth()-1)
     }; 
    },
    SET_PREVIEW(state,payload){ state.preview=payload.preview; },
    UNSET_PREVIEW(state){ state.preview=[]; },
    EDIT_PREVIEW (state, payload) {
      const item = state.preview.find(item => item.id === payload.id);
      item.send = payload.send
      Object.assign(item, payload);
    },
    SET_SAVEDPREVIEW(state,payload){ state.savedPreview=payload.savedPreview; },
    UNSET_SAVEDPREVIEW(state){ state.savedPreview=false; },
    SET_MODAL_BOOK(state, payload){
      for(let i=0; i<Object.keys(payload).length; i++){
        for(let j=0; j<Object.keys(state.modal_book).length; j++){
          if(Object.keys(payload)[i]==Object.keys(state.modal_book)[j]){
            switch(Object.keys(state.modal_book)[j]){
                case 'create': state.modal_book.create=payload.create; break;
                case 'edit': state.modal_book.edit=payload.edit; break;
                case 'delete': state.modal_book.delete=payload.delete; break;
                case 'pdf': state.modal_book.pdf=payload.pdf; break;
                case 'pdfWidgets': state.modal_book.pdfWidgets=payload.pdfWidgets; break;
                default: console.log('Error');
            }
          }
        }
      }
    },
    UNSET_MODAL_BOOK(state){
      state.modal_book = {
        create : false,
        edit : false,
        delete : false,
        pdf:false,
        pdfWidgets:false
      }
    },
    SET_BOOKS(state,payload){ state.books=payload.books; },
    UNSET_BOOKS(state){ state.books=[]; },
    SET_LOADING(state,payload){ state.loading=payload.loading; },
    UNSET_LOADING(state){ state.loading=true; },
    SET_LAST_INVOICE(state,payload){ state.last_invoice=payload.last_invoice; },
    UNSET_LAST_INVOICE(state){ state.last_invoice=[]; },
    SET_SEARCHBOOK(state,payload){ state.searchBook=payload.searchBook; },
    UNSET_SEARCHBOOK(state){ state.searchBook=null; },
  },
  actions:{
    async LOAD_BASE_BOOK({commit, dispatch, getters }, payload){
      await axios.post(getters.GET_SERVER+'/api/book/base', {
      })
      .then(response=> {
        if( response.data.status ){
          commit('SET_COMPANIES',{companies:response.data.companies})
          commit('SET_SUBCOMPANIES',{subcompanies:response.data.subcompanies})
          commit('SET_BASEUSERS',{baseUsers:response.data.users})
        } else {
          payload.vm.$toast.error({title:"Ошибка", message: response.data.message});
        }
      })
      .catch(err=>{
        payload.vm.$toast.error({title:"Ошибка", message: err.message});
      });
    },
    async CREATE_BOOK({commit, dispatch, getters }, payload){
      await axios.post(getters.GET_SERVER+'/api/book/create', {
        book: getters.GET_BOOK
      })
      .then(response=> {
        if( response.data.status ){
          dispatch('LOAD_BOOKS')
        } else {
          payload.vm.$toast.error({title:"Ошибка", message: response.data.message});
        }
      })
      .catch(err=>{
        payload.vm.$toast.error({title:"Ошибка", message: err.message});
      });
    },
    async LOAD_BOOKS({commit, dispatch, getters }, payload){
      commit('SET_LOADING', {loading:true})
      let searchs=getters.GET_SEARCHBOOK;
      if(Object.keys(searchs).length!=0){
        let result='';
        Object.keys(searchs).map(search=>{
          if(!searchs[search]){
            delete searchs[search];
          }
          result +=`&${search}=`+searchs[search];
        })
        searchs=result;
      } else { searchs=''}
      await axios.get(getters.GET_SERVER+'/api/book/show?page='+getters.GET_BOOKS_PAGINATION.page+'&per_page='+getters.GET_BOOKS_PAGINATION.per_page+searchs)
      .then(response=> {
        if( response.data.status ){
          commit('SET_BOOKS', {books:response.data.books})
          commit('SET_LOADING', {loading:false})
          let pagination = getters.GET_BOOKS_PAGINATION;
          pagination.total=response.data.total;
          commit('SET_BOOKS_PAGINATION',{books_pagination:pagination});
        } else {
          payload.vm.$toast.error({title:"Ошибка", message: response.data.message});
        }
      })
      .catch(err=>{
        console.log(err)
        payload.vm.$toast.error({title:"Ошибка", message: err.message});
      });
    },
    async UPLOAD_EXCEL({commit, dispatch, getters }, payload){
      let form = new FormData()
      form.append('excelFile', payload.file);
      form.append('subcompany', payload.subcompanyId);
      await axios.post(getters.GET_SERVER+'/api/book/upload', 
        form,
        {
          headers: {
            'content-Type': 'multipart/form-data'
          }
        })
      .then(response=> {
        if( response.data.status ){
          getters.GET_BOOK.type = response.data.type;

          // response.data.cards.map(card=>{
          //   for(let i=0; i<getters.GET_COMPANIES.length; i++){
          //     if(card.client_id == getters.GET_COMPANIES[i].code){
          //       card.name = getters.GET_COMPANIES[i].name;
          //       card.emails = getters.GET_COMPANIES[i].emails;
          //       card.payment_period = getters.GET_COMPANIES[i].payment_period;
          //       break;
          //     }
          //   }
          // })
          
          response.data.preview.map((company)=>{
            let myCompanies = getters.GET_COMPANIES;
            myCompanies = myCompanies.filter(company => { 
              return company.subcompanyId === payload.subcompanyId
            })
            for(let i=0; i<myCompanies.length; i++){
              if(myCompanies[i].code == company.client_id && myCompanies[i].subcompanyId === payload.subcompanyId){
                company.coef = myCompanies[i].coef;
                company.name = myCompanies[i].name;
                company.emails = myCompanies[i].emails;
                company.payment_period = myCompanies[i].payment_period;
                break;
              }
            }
            company.data.map(data=>{
              if(data.overprice){
                data.coef = company.coef
                data.card_amount = Number(company.coef) ? Number(company.coef)*Number(data.count) : 0*Number(data.count)
                data.amount = data.card_amount + Number(data.net);
                data.amount_vat = data.amount * 1.21;
              } else {
                data.card_amount = 0*Number(data.count)
                data.amount = data.card_amount + Number(data.net);
                data.amount_vat = data.amount * 1.21;
              }
              
            })
          })
          let preview=[];
          response.data.preview.map((company)=>{
            if(company.name){
              preview.push(company)
            }
          })
          
          commit('SET_PREVIEW', {preview:preview})
          commit('SET_SAVEDPREVIEW', {savedPreview:false})
        } else {
          payload.vm.$toast.error({title:"Ошибка", message: response.data.message});
        }
      })
      .catch(err=>{
        payload.vm.$toast.error({title:"Ошибка", message: err.message});
      });
    },
    async LOAD_LAST_INVOICE({commit, dispatch, getters }, payload){
      await axios.post(getters.GET_SERVER+'/api/numbering/getnumber', {
        subcompany: getters.GET_BOOK.subcompany
      })
      .then(response=> {
        if( response.data.status ){
          commit('SET_LAST_INVOICE',{last_invoice:response.data.number})
        } else {
          payload.vm.$toast.error({title:"Ошибка", message: response.data.message});
        }
      })
      .catch(err=>{
        payload.vm.$toast.error({title:"Ошибка", message: err.message});
      });
    }, 
    //DELETE
  },
  getters:{
    GET_BOOK:(state)=>state.book,
    GET_PREVIEW:(state)=>state.preview,
    GET_SAVEDPREVIEW:(state)=>state.savedPreview,
    GET_MODAL_BOOK:(state)=>state.modal_book,
    GET_BOOKS:(state)=>state.books,
    GET_LAST_INVOICE:(state)=>state.last_invoice,
    GET_LOADING:(state)=>state.loading,
    GET_SEARCHBOOK:(state)=>state.searchBook,
  }
}

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}