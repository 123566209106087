<template>
  <div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr :style="{'background-color': colors.table}">
            <th class="text-left" style="color:white;">
              #
            </th>
            <th class="text-left" style="color:white;">
              Date
            </th>
            <th class="text-left" style="color:white;">
              Name
            </th>
            <th class="text-left" style="color:white;">
              Prefix
            </th>
            <th class="text-left" style="color:white;">
              Index
            </th>
            <th class="text-left" style="color:white;">
              Suffix
            </th>
            <th class="text-left" style="color:white;">
              Delimiter
            </th>
            <th class="text-left" style="color:white;">
              Count
            </th>
            <th class="text-left" style="color:white;">
              Comments
            </th>
            <th class="text-left" style="color:white;">
              Manager
            </th>
            <th class="text-left" style="color:white;">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(numbering,index) in numberings" :key="index"
          >
            <td class="brdr" style="max-width:50px;">
              {{index+1}}
            </td>
            <td class="brdr" style="max-width:100px;">
              {{format_date(numbering.createdAt)}}
            </td>
            <td class="ell brdr" style="max-width:100px;">
              {{numbering.name}}
            </td>
            <td class="ell brdr" style="max-width:100px;">
              {{numbering.prefix}}
            </td>
            <td class="ell brdr" style="max-width:100px;">
              {{numbering.index}}
            </td>
            <td class="ell brdr" style="max-width:100px;">
              {{numbering.suffix}}
            </td>
            <td class="ell brdr" style="max-width:100px;">
              {{numbering.split}}
            </td>
            <td class="ell brdr" style="max-width:100px;">
              {{numbering.count}}
            </td>
            <td class="ell brdr" style="max-width:80px;">
              {{numbering.comment}}
            </td>
            <td class="brdr" style="max-width:80px;">
              {{numbering.author}}
            </td>
            <td class="brdr">
              <v-row>
                <v-col align="center" class="pa-0">
                  <v-btn
                    icon
                    color="warning"
                    @click="edit_numbering(index, numbering.id)"
                  >
                    <v-icon>mdi-circle-edit-outline</v-icon>
                  </v-btn>
                </v-col>
                <v-col align="center" class="pa-0">
                  <v-btn
                    icon
                    color="error"
                    @click="delete_numbering(index, numbering.id)"
                  >
                    <v-icon>mdi-delete-circle-outline</v-icon>
                  </v-btn>      
                </v-col>
              </v-row>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr :style="{'background-color': colors.table}">
            <td colspan="100"></td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
    <EditNumbering :numbering="editNumbering" />
    <DeleteNumbering :numbering="deleteNumbering" />
  </div>
</template>

<script>
import EditNumbering from '../edit/EditNumbering.vue'
import DeleteNumbering from '../delete/DeleteNumbering.vue'
export default {
  name : 'SubCompaniesTable',
  components : { EditNumbering, DeleteNumbering },
  data: ()=>({
    editNumbering:{},
    deleteNumbering:{},
  }),
  computed : {
    numberings : { 
      get:function(){ return this.$store.getters.GET_NUMBERINGS },
      set:function(value){ return this.$store.commit('SET_NUMBERINGS',{numberings:value}) }
    },
    colors : { 
      get:function(){ return this.$store.getters.GET_COLORS }
    },
  },
  mounted(){
    this.loadNumberings();
  },
  methods : {
    loadNumberings(){
      this.$store.dispatch('LOAD_NUMBERINGS');
    },
    format_date(_date){ return new Date(_date).toLocaleDateString("ru-RU"); },
    edit_numbering(index, id){
      if( this.numberings[index].id == id ){
        this.editNumbering=Object.assign({}, this.numberings[index]);
        this.$store.commit('SET_MODAL_NUMBERING',{edit:true});
      }
    },
    delete_numbering(index, id){
      if( this.numberings[index].id == id ){
        this.deleteNumbering=Object.assign({}, this.numberings[index]);
        this.$store.commit('SET_MODAL_NUMBERING',{delete:true});
      }
    }
  }
}
</script>

<style>

</style>