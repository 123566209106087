import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/axios'
import CxltToastr from 'cxlt-vue2-toastr'
import 'cxlt-vue2-toastr/dist/css/cxlt-vue2-toastr.css'

Vue.config.productionTip = false;

var toastrConfigs = {
  position: 'bottom right',
  showDuration: 5000,
  icon:"",
  showMethod:'bounce',
  hideMethod:'flipOutY'
}
Vue.use(CxltToastr, toastrConfigs)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
