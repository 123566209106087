<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col align="end">
          <v-btn
            small
            @click="addCard()"
            color="green accent-3">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <v-col align="center">
          <h2> Books </h2>
        </v-col>
        <v-col align="start">
          <v-btn
            small
            @click="reloadCards()"
            color="cyan accent-3">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  methods : {
    reloadCards(){
      this.$store.dispatch('LOAD_BOOKS');
    },
    addCard(){
      this.$store.dispatch('CREATE_BOOK',{vm:this});
    }
  }
}
</script>

<style>

</style>