<template>
  <div>
    <v-text-field
      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
      :type="show ? 'text' : 'password'"
      label="password"
      class="input-group--focused"
      v-model="user.password"
      @click:append="show = !show"
      dense outlined hide-details
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name : 'Password',
  props : ['user'],
  data(){
    return {
      show: false,
    }
  }
}
</script>

<style>

</style>