<template>
  <div>
    <v-row>
      <v-col>
        <v-textarea
          v-model="company.address"
          rows="3"
          label="Address"
          outlined dense hide-details
        ></v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name : 'Address',
  props : ['company']
}
</script>

<style>

</style>