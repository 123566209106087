<template>
  <div> 
    <v-row>
      <v-col cols="3">
        SubCompany
      </v-col>
      <v-col>
        <v-select
          v-model="card.subcompany"
          :items="subcompanies"
          item-text="name"
          item-value="id"
          dense outlined hide-details
        ></v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props : ['card'],
  computed : {
    subcompanies : {
      get:function(){ return this.$store.getters.GET_SUBCOMPANIES },
      set:function(value){ return this.$store.commit('SET_SUBCOMPANIES',{subcompanies:value}) }
    },
  },
}
</script>

<style>

</style>