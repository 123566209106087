<template>
  <div>
    <v-app :style="{'background-color' : colors.background}">
      <Header></Header>
      <v-main>
        <transition name="slide-fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Header from '@/components/layouts/header/HeaderComponent.vue';

export default {
  name: 'App',

  components:{
    Header
  },
  data: () => ({
  }),
  computed: {
    colors:{ 
      get:function(){ return this.$store.getters.GET_COLORS }
    },
  },
  mounted(){
    
  },
  methods:{
    
  }
};
</script>
