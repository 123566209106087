<template>
  <div>
    <v-dialog 
      max-width="1600"
      v-model="modal_book.edit"
      scrollable
    >
      <v-card>
        <v-card-title 
          class="headline"
          :style="{'background-color': colors.table}"
          style="color:white;"
        >
          Card ID: {{card.client_id}} - {{getCompany(card.client_id)}}
        </v-card-title>
        <v-card-text style="padding:0px;">
          <v-container>
            <v-row>
              <v-col>
                <Widgets :card="card" />
              </v-col>
            </v-row>
            <v-row v-if="index.type == 1">
              <v-col class="pt-0">
                <Table :card="card" />
              </v-col>
            </v-row>
            <v-row v-if="index.type == 2">
              <v-col class="pt-0">
                <TableP :card="card" />
              </v-col>
            </v-row>
          </v-container>
            
          
        </v-card-text>
        
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            @click="modal_book.edit=false"
          >
            Закрыть
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="success darken-1"
            text
            @click="save_card()"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Table from './Table.vue';
import TableP from './TableP.vue';
import Widgets from './Widgets.vue';
export default {
  props : ['card', 'index'],
  components : { Table, Widgets, TableP },
  computed : {
    book : { 
      get:function(){ return this.$store.getters.GET_BOOK },
      set:function(value){ return this.$store.commit('SET_BOOK',{book:value}) }
    },
    modal_book : { 
      get:function(){ return this.$store.getters.GET_MODAL_BOOK },
      set:function(value){ return this.$store.commit('SET_MODAL_BOOK',{modal_book:value}) }
    },
    colors:{ 
      get:function(){ return this.$store.getters.GET_COLORS }
    },
    companies : {
      get:function(){ return this.$store.getters.GET_COMPANIES },
      set:function(value){ return this.$store.commit('SET_COMPANIES',{companies:value}) }
    },
    preview : { 
      get:function(){ return this.$store.getters.GET_PREVIEW },
      set:function(value){ return this.$store.commit('SET_PREVIEW',{preview:value}) }
    },
  },
  methods : {
    getCompany(id){
      let companies = this.companies.filter(company=>company.subcompanyId==this.book.subcompany)
      for(let i=0; i<companies.length; i++){
        if(companies[i].code == id){
          return companies[i].name;
          break;
        }
      }
    },
    save_card(){
      this.preview.splice(this.index.index, 1, this.card) ;
      this.modal_book.edit=false;
      this.$toast.success({title:"Changed", message:"Card was changed"})
    }
  }
}
</script>

<style>
  .bb{
    border: 0.25px solid #e0e0e0;
  }
</style>