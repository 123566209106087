<template>
  <div>
    <v-textarea
      v-model="company.comment"
      rows="2"
      label="Comment"
      outlined dense hide-details
    ></v-textarea>
  </div>
</template>

<script>
export default {
  name : 'Comment',
  props : ['company']
}
</script>

<style>

</style>