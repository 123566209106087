<template>
  <div>
    <v-container class="pl-0 pr-0">
      <v-row>
        <v-col align="center" class="pl-0 pr-0 pb-0">
          <h2>Book # {{$route.params.id}}</h2>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col style="padding-left:0px;">
          <Widgets />
        </v-col>
      </v-row>
    </v-container>
    <v-container style="padding-left:0px;">
      <v-row>
        <v-col :style="[{'background-color': '#FAFAFA'},{'border':'1px solid'+colors.table}]">
          <transition name="slide-fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </v-col>
      </v-row>
    </v-container>
    <v-container style="padding-left:0px;">
      <v-row>
        <v-col class="pl-0">
          <v-btn
            small
            color="error"
            @click="cancel_book()"
          >
            cancel
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="pr-0" align="end">
          <v-btn
            small
            color="success"
            @click="save_book()"
          >
            save
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Widgets from './Widgets.vue';
export default {
  components : { Widgets },
  computed : {
    colors:{ 
      get:function(){ return this.$store.getters.GET_COLORS }
    },
    book : { 
      get:function(){ return this.$store.getters.GET_BOOK },
      set:function(value){ return this.$store.commit('SET_BOOK',{book:value}) }
    },
  },
  mounted(){
    this.loadBase();
  },
  methods : {
    loadBase(){
      this.$store.commit('UNSET_BOOK');
      //this.$store.dispatch('LOAD_BOOK_BASE', {vm:this});
    },
    cancel_book(){
      this.$router.push({path: '/books'})
    },
    save_book(){
      let a = this.$router.currentRoute.path.split("/")[3];
      switch(a){
        case 'main' : this.save_book_main(); break;
        default :console.log('EditBooksComponent Default');
      }
    },
    save_book_main(){
      let errors = this.check_save_book_main();
      if(errors.length==0){
        this.$store.dispatch('SAVE_BOOK_MAIN', {vm:this, book:this.$store.getters.GET_BOOK}).then(()=>{
          this.$store.dispatch('LOAD_MAIN_BOOK', {id: this.$route.params.id})
        })
      } else {
        errors.map(err=>{
          this.$toast.error({title:"Ошибка", message:err})
        })
      }
    },
    check_save_book_main(){
      let errors = [];
      if(!this.book.subcompany){errors.push("Select SubCompany")}
      if(!this.book.paydate){errors.push("Select Invoice Date")}
      if(!this.book.month){errors.push("Select Month")}
      return errors
    }
  }
}
</script>

<style>

</style>