<template>
  <div>
    
    <v-simple-table dense class="bb">
      <template v-slot:default>
        <tbody>
          <td colspan="19"></td>
          <td class="ell brdr pl-2 pr-2" style="min-width:80px; width:80px; max-width:80px;">{{calc.calcSumCardAmonut(card)}}</td>
          <td class="ell brdr pl-2 pr-2" style="min-width:80px; width:80px; max-width:80px;">{{calc.calcSumAmonut(card)}}</td>
          <td class="ell brdr pl-2 pr-2" style="min-width:80px; width:80px; max-width:80px;">{{calc.calcSumAmonutVAT(card)}}</td>
        </tbody>
        <tbody>
          <tr style="background-color:#CFD8DC">
            <th class="ell">Trn. ID</th>
            <th class="ell">Data</th>
            <th class="ell">Kvito Nr.</th>
            <th class="ell">Kortelės Nr.	</th>
            <th class="ell" style="min-width:70px; width:70px; max-width:70px;">Papildomas užrašas ant kortelės</th>
            <th class="ell">Degalinės adresas	</th>
            <th class="ell" style="min-width:50px; width:50px; max-width:50px;">Produkto kodas	</th>
            <th class="ell">Produktas	</th>
            <th class="ell" style="min-width:80px; width:80px; max-width:80px;">Valiuta	</th>
            <th class="ell">Kaina su PVM	</th>
            <th class="ell">Kiekis, L	</th>
            <th class="ell">Kiekis, vnt.	</th>
            <th class="ell">Nuolaida su PVM	</th>
            <th class="ell" style="min-width:80px; width:80px; max-width:80px;">Suma po nuolaidos be PVM	</th>
            <th class="ell" style="min-width:80px; width:80px; max-width:80px;">Suma po nuolaidos su PVM	</th>
            <th class="ell" style="min-width:80px; width:80px; max-width:80px;">Suma po nuolaidos be PVM, EUR	</th>
            <th class="ell" style="min-width:80px; width:80px; max-width:80px;">Suma po nuolaidos  su PVM, EUR	</th>
            <th colspan="2">%</th>
            <th class="ell" style="min-width:80px; width:80px; max-width:80px;">KORTELĖS MOKESTIS</th>
            <th class="ell" style="min-width:80px; width:80px; max-width:80px;">Mokėtina suma be PVM</th>
            <th class="ell" style="min-width:80px; width:80px; max-width:80px;">Mokėtina suma su PVM</th>

            <!-- trn_id: Trn. ID,
            datetime : Data,
            receipt  : Kvito Nr.,
            card_code : Kortelės Nr.,
            card_additional: Papildomas užrašas ant kortelės,
            //driver  : Vairuotojas,
            petrol  : Degalinės adresas,
            product_code : Produkto kodas
            product_name  : Produktas,          
            currency: Valiuta,          
            price_vat : Kaina su PVM,
            count : Kiekis, L,
            count_unit : Kiekis, vnt.,
            discount_vat : Nuolaida su PVM,
            price_after_discount_no_vat : Suma po nuolaidos be PVM,
            price_after_discount_vat: Suma po nuolaidos su PVM,
            price_after_discount_no_vat_eur : Suma po nuolaidos be PVM, EUR,
            price_after_discount_vat_eur : Suma po nuolaidos  su PVM, EUR, 
            net  : Suma po nuolaidos be PVM, EUR -->
          </tr>
        </tbody>
        <tbody>
          <tr
            v-for="(data,indx) in card.data" :key="indx"
          >
            <td class="brdr pl-2" style="min-width:90px; width:90px; max-width:90px;">
              {{data.trn_id}}
            </td>
            <td class="brdr pl-2" style="min-width:150px; width:150px; max-width:150px;">
              {{data.datetime}}
            </td>
            <td class=" brdr" style="min-width:100px; width:100px; max-width:100px;">
              {{data.receipt}}
            </td>
            <td class="brdr pl-2" style="min-width:180px; width:180px; max-width:180px;">
              {{data.card_code}}
            </td>            
            <td class="ell brdr" style="min-width:70px; width:70px; max-width:70px;">
              {{data.card_additional}}
            </td>
            <td class="ell brdr" style="min-width:120px; width:120px; max-width:120px;">
              {{data.petrol}}
            </td>
            <td class="ell brdr" style="min-width:50px; width:50px; max-width:50px;">
              {{data.product_code}}
            </td>
            <td class="ell brdr" style="min-width:120px; width:120px; max-width:120px;">
              {{data.product_name}}
            </td>
            <td class="ell brdr" style="min-width:80px; width:80px; max-width:80px;">
              {{data.currency}}
            </td>
            <td class="ell brdr" style="min-width:120px; width:120px; max-width:120px;">
              {{data.price_vat}}
            </td>
            <td class=" brdr" style="min-width:80px; width:80px; max-width:80px;">
              {{data.count}}
            </td>
            <td class=" brdr" style="min-width:80px; width:80px; max-width:80px;">
              {{data.count_unit}}
            </td>
            <td class=" brdr" style="min-width:80px; width:80px; max-width:80px;">
              {{data.discount_vat}}
            </td>
            <td class=" brdr" style="min-width:80px; width:80px; max-width:80px;">
              {{data.price_after_discount_no_vat}}
            </td>
            <td class=" brdr" style="min-width:80px; width:80px; max-width:80px;">
              {{data.price_after_discount_vat}}
            </td>
            <td class=" brdr" style="min-width:80px; width:80px; max-width:80px;">
              {{data.net}}
            </td>
            <td class=" brdr" style="min-width:80px; width:80px; max-width:80px;">
              {{data.price_after_discount_vat_eur}}
            </td>
            <td class=" brdr" style="min-width:60px; width:60px; max-width:60px; padding:0">
              <v-text-field
                v-model="data.coef"
                @keyup="changeOverprice(indx)"
                dense outlined hide-details
              ></v-text-field>
            </td>
            <td class=" brdr" style="min-width:60px; width:60px; max-width:60px;">
              <v-checkbox
                v-model="data.overprice"
                color="primary"
                @change="changeOverprice(indx)"
                hide-details dense
              ></v-checkbox>
            </td>
            <td class="ell brdr" style="min-width:50px; width:50px; max-width:50px;">
              {{(data.card_amount)}}
            </td>
            <td class="ell brdr" style="min-width:80px; width:80px; max-width:80px;">
              {{data.amount}}
            </td>
            <td class="ell brdr" style="min-width:80px; width:80px; max-width:80px;">
              {{data.amount_vat}}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import calc from './calc';
export default {
  props : ['card'],
  computed : {
    calc : ()=>{
      return calc;
    },
    colors:{ 
      get:function(){ return this.$store.getters.GET_COLORS }
    },
    companies : {
      get:function(){ return this.$store.getters.GET_COMPANIES },
      set:function(value){ return this.$store.commit('SET_COMPANIES',{companies:value}) }
    },
    coefChange : function(){
      return this.card.coef;
    }
  },
  watch : {
    coefChange(val){
      calc.changeCalc();
    }
  },
  methods : {
    getCompany(id){
      for(let i=0; i<this.companies.length; i++){
        if(this.companies[i].code == id){
          return this.companies[i].name;
          break;
        }
      }
    },
    changeOverprice(index){
      calc.changeOverprice(this.card.data[index])
    }
  }
}
</script>

<style>

</style>