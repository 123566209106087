<template>
<div>
    <v-app-bar
      app
      :color="colors.header"
      dark
      dense
    >
      <v-app-bar-nav-icon @click="drawer=!drawer" class="hidden-md-and-up"></v-app-bar-nav-icon>
      <v-toolbar-title style="cursor: pointer;" @click="toHome()">Progit | Cards</v-toolbar-title>
      <v-toolbar-items v-if="!drawer" class="hidden-sm-and-down">
        <v-btn
          text
          v-for="(item,i) in navbar_items" :key="i"
          :to="{ name: item.nameRoute}"
        >
          {{item.title}}
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-btn v-if="authenticated" text icon  @click="openColors()">
        <v-icon>mdi-tune</v-icon>
      </v-btn>
      <v-menu
        :close-on-content-click="false"
        :nudge-width="200"
        offset-y
        v-if="authenticated"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            text
            icon
          >
            <v-icon dark>
              mdi-account-circle-outline
            </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{user.first_name}} {{user.last_name}}</v-list-item-title>
                <v-list-item-subtitle>{{user.role}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      
      <v-btn 
        v-if="!authenticated" text icon @click="$router.push('/login')"
      >
       <v-icon> mdi-login </v-icon>
      </v-btn>
      <v-btn icon
        v-else text @click="logout()"
      >
        <v-icon>
          mdi-logout
        </v-icon>
      </v-btn>

    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list nav dense>
        <v-list-item-group active-class="deep-purple--text text--accent-4">

          <v-list-item @click.prevent=" drawer=!drawer;">
            <v-list-item-icon>
              <v-icon left>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{user.first_name}} {{user.last_name}}
            </v-list-item-title>
          </v-list-item>

          <v-list-item 
            @click.prevent=" drawer=!drawer;" 
            v-for="(item,i) in navbar_items" 
            :key="i"
            :to="{ name: item.nameRoute}"
          >
            <v-list-item-title>
              {{item.title}}
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click.prevent="drawer=!drawer; logout();" >
            <v-list-item-icon>
              <v-icon left>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              Выйти
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <Colors></Colors>
  </div>    
</template>

<script>
import Colors from '@/components/colors/ColorsComponent'

export default {
  name: 'HeaderComponent',

  components:{
    Colors
  },
  data: () => ({
    drawer:false
  }),
  computed: {
    user:{ 
      get:function(){ return this.$store.getters.GET_USER },
    },
    navbar_items:{ 
      get:function(){ return this.$store.getters.GET_NAVBAR_ITEMS },
    },
    authenticated:{ 
      get:function(){ return this.$store.getters.GET_AUTHENTICATED },
      set:function(value){ return this.$store.commit('SET_AUTHENTICATED',{authenticated:value}) }
    },
    modal_colors:{ 
      get:function(){ return this.$store.getters.GET_MODAL_COLORS },
      set:function(value){ return this.$store.commit('SET_MODAL_COLORS'), {modal_colors:value} }
    },
    colors:{ 
      get:function(){ return this.$store.getters.GET_COLORS }
    },
  },
  mounted(){
    this.flash();
  },
  methods:{
    flash()
    {
      if(!this.authenticated){
        if(localStorage.getItem('token'))
        {
          let task = new Promise((res, rej)=>{
            try{
              this.$store.commit('SET_USER',{token:localStorage.getItem('token')});
              return res();
            }
            catch{
              return rej();
            }
          })
          task.then(()=>{
            if(!this.authenticated)
            {
              this.$store.dispatch('LOGOUT');
            }
            else{
              this.load_navbar_items();
            }
          })
        }
      }
    },
    logout(){
      this.$store.dispatch('LOGOUT', {vm:this});
    },
    load_navbar_items()
    {
      if(this.authenticated)
      {
        this.$store.dispatch('LOAD_NAVBAR_ITEMS');
      }
      
    },
    toHome(){
      this.$router.push('/')
    },
    openColors()
    {
      this.$store.commit('UNSET_MODAL_COLORS');
      this.$store.commit('SET_MODAL_COLORS',{modal_colors:true})
    }
  }  
}
</script>

<style>

</style>