<template>
  <div>
    <v-container>
      <v-row>
        <v-col align="center" style="padding-bottom:0px; padding-top:0px;">
          <h2>Base</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col style="padding:0px;">
          <Widgets />
        </v-col>
      </v-row>
      <v-row>
        <v-col style="padding:0px;">
          <router-view></router-view>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Widgets from './Widgets.vue'
export default {
  components: { Widgets },
  name : 'BaseComponent',
  mounted(){
  }
}
</script>

<style>

</style>