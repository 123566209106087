<template>
  <div v-if="modal_colors">
    <v-dialog
      max-width="1200"
      v-model="modal_colors"
      scrollable
    >
      <v-card>
        <v-card-title 
          class="headline"
          :style="{'background-color' : colors.header}"
          style="color:white"
          dark
        >
          Colors
        </v-card-title>
        <v-card-text
          style="height: 700px;"
        >
          <v-container>
            <v-row>
              <v-col cols="6" align="center">
                Navbar
              </v-col>
              <v-col cols="6">
                <v-color-picker
                  hide-canvas
                  v-model="colors.header"
                ></v-color-picker>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="6" align="center">
                Tables
              </v-col>
              <v-col cols="6">
                <v-color-picker
                  hide-canvas
                  v-model="colors.table"
                ></v-color-picker>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="6" align="center">
                Background
              </v-col>
              <v-col cols="6">
                <v-color-picker
                  dot-size="30"
                  v-model="colors.background"
                ></v-color-picker>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="green darken-1"
            text
            @click="setDefault()"
          >
            Default
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="modal_colors=false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name : 'Colors',
  computed: {
    colors:{ 
      get:function(){ return this.$store.getters.GET_COLORS },
      set:function(value){ return this.$store.commit('SET_COLORS',value) }
    },
    modal_colors:{ 
      get:function(){ return this.$store.getters.GET_MODAL_COLORS },
      set:function(value){ return this.$store.commit('SET_MODAL_COLORS',{modal_color:value}) }
    },
  },
  methods:{
    setDefault(){
      this.$store.commit('UNSET_COLORS');
    }
  }
}
</script>

<style>

</style>