<template>
  <div>
    <v-btn-toggle
      tile
      color="deep-purple accent-3"
      group
    >
      <v-btn v-for="(widget, index) in widgets" :key="index"
        small
        value="left" 
        style="margin-left:0px;"
        :to="{ name: widget.route}"
      >
        {{widget.name}}
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
export default {
  name : 'WidgetsBase',
  data: ()=>({
    widgets:[
      {
        name : 'Users',
        route : 'AdminBaseUsers'
      },
      {
        name : 'SubCopmanies',
        route : 'AdminBaseSubCompanies'
      },
      {
        name : 'Numbering',
        route : 'AdminBaseNumbering'
      },
    ]
  }),
}
</script>

<style>

</style>