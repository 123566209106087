<template>
  <div>
    <v-dialog 
      max-width="1200"
      v-model="modal_numbering.delete"
      scrollable
    >
      <v-card>
        <v-card-title 
          class="headline"
          style="background-color: #ff5252; color:white;"
        >
          Delete Numbering ID: {{numbering.id}}
        </v-card-title>
        <v-card-text style="padding:0px;">
          <v-container>
            <v-row>
              <v-col>
                Delete Numbering {{numbering.name}} ?
              </v-col>
            </v-row>
          </v-container>
            
          
        </v-card-text>
        
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            @click="modal_numbering.delete=false"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="save_delete_numbering()"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props : ['numbering'],
  computed : {
    modal_numbering:{ 
      get:function(){ return this.$store.getters.GET_MODAL_NUMBERING },
      set:function(value){ return this.$store.commit('SET_MODAL_NUMBERING',{modal_numbering:value}) }
    },
  },
  methods : {
    save_delete_numbering(){
      this.$store.dispatch('DELETE_NUMBERING', {numbering:this.numbering});
    }
  }
}
</script>

<style>

</style>