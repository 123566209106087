<template>
  <div>
    <div class="horizontal">
      <img 
        width="100%"
        src="@/assets/home.webp" 
      />
      <div 
        class="top-left" 
        :style="[{'color': colors.header}]">
          <span style="font-size: 50px;"> One Plus One <br/> More Than Two </span>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <span style="font-size: 25px;"> Site is under construction </span>
      </div>
    </div>
    <div class="vertical">
      <img 
        width="100%"
        src="@/assets/home-h.webp" 
      />
      <div 
        class="top-left" 
        :style="[{'color': colors.header}]">
          <span style="font-size: 50px;"> One Plus One <br/> More Than Two </span>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <span style="font-size: 25px;"> Site is under construction </span>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  computed:{
    colors:{ 
      get:function(){ return this.$store.getters.GET_COLORS }
    },
  }
}
</script>

<style>
  .top-left {
    position: absolute;
    top: 30%;
    left: 5%;
    font-family: Verdana, Helvetica, Arial, sans-serif;
    color:#E1F5FE;
  };
  .horizontal{
    display: block;
  }
  .vertical{
    display: none;
  }
  @media screen and (max-width: 1000px) {
    .horizontal{
      display: none;
    }
    .vertical{
      display: block;
    }
  }
</style>