<template>
  <div>
    <v-row>
      <v-col>
        <v-text-field
          v-model="company.email_user"
          type="email"
          label="Email"
          suffix="@gmail.com"
          class="right-input"
          dense outlined hide-details
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="company.email_password"
          label="Password"
          :type="show ? '' : 'password'"
          dense outlined hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="company.email_cc"
          type="email"
          label="СС"
          class="right-input"
          dense outlined hide-details
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props : ['company'],
  data: ()=>({
    show:false
  })
}
</script>

<style>

</style>