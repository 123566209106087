export default
{
  state: {
    
  },
  mutations: {

  },
  actions:{
    async EXCEL_CARD_DOWN({commit, dispatch, getters }, payload){
      await axios.post(getters.GET_SERVER+'/api/card/download/excel',{
        card : payload.card,
        type : getters.GET_BOOK.type
      })
      .then(function (response) {        
        let  b64 = response.data.file;
        let  link = document.createElement('a');
        link.download = 'card.xlsx';
        link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + b64;
        document.body.appendChild(link);
        link.click();
      })
      .catch(err=>{
        console.log(err)
      });
    },
    async PDF_CARD_DOWN({commit, dispatch, getters }, payload){
      await axios.post(getters.GET_SERVER+'/api/card/download/pdf',{
        card : payload.card,
        type : getters.GET_BOOK.type,
      })
      .then(function (response) {        
        let  b64 = response.data.file;
        let  link = document.createElement('a');
        link.download = 'card.pdf';
        link.href = 'data:application/pdf;base64,' + b64;
        document.body.appendChild(link);
        link.click();
      })
      .catch(err=>{
        payload.vm.$toast.error({title:err.response.data.message});
      });
    },

  },
  getters:{
    
  }
}