<template>
  <div>
    <div class="text-center">
      <h3>Total</h3>
    </div>           
    <v-row>
      <v-col v-if="hasFUNN()>0">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr :style="{'background-color': colors.table}">
                <th class="text-center" style="color:white;">
                  FUNN
                </th>
                <th class="text-center" style="color:white;">
                  Sum
                </th>
                <th class="text-center" style="color:white;">
                  Sum VAT
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center">
                <td>Card</td>
                <td>{{(total.funn.card.sum).toFixed(2)}}</td>
                <td>{{(total.funn.card.sumVat).toFixed(2)}}</td>
              </tr>
              <tr class="text-center">
                <td>Petrol</td>
                <td>{{(total.funn.petrol.sum).toFixed(2)}}</td>
                <td>{{(total.funn.petrol.sumVat).toFixed(2)}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr :style="[{'background-color': colors.table}, {'color':'white'}]" class="text-center">
                <td>
                  Profit
                </td>
                <td>
                  {{( Number( total.funn.card.sum ) - Number( total.funn.petrol.sum ) ).toFixed(2)}}
                </td>
                <td>
                  {{( Number( total.funn.card.sumVat ) - Number( total.funn.petrol.sumVat ) ).toFixed(2)}}
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
      <v-col v-if="hasCircleK()>0">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr :style="{'background-color': colors.table}">
                <th class="text-center" style="color:white;">
                  Circle K
                </th>
                <th class="text-center" style="color:white;">
                  Sum
                </th>
                <th class="text-center" style="color:white;">
                  Sum VAT
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center">
                <td>Card</td>
                <td>{{(total.circleK.card.sum).toFixed(2)}}</td>
                <td>{{(total.circleK.card.sumVat).toFixed(2)}}</td>
              </tr>
              <tr class="text-center">
                <td>Petrol</td>
                <td>{{(total.circleK.petrol.sum).toFixed(2)}}</td>
                <td>{{(total.circleK.petrol.sumVat).toFixed(2)}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr :style="[{'background-color': colors.table}, {'color':'white'}]" class="text-center">
                <td>
                  Profit
                </td>
                <td>
                  {{ ( Number( total.circleK.card.sum ) - Number( total.circleK.petrol.sum ) ).toFixed(2)}}
                </td>
                <td>
                  {{ ( Number( total.circleK.card.sumVat ) - Number( total.circleK.petrol.sumVat ) ).toFixed(2)}}
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    
    
  </div>
</template>

<script>
import calc from '../card/calc';
export default {
  props: ['cards','book'],
  data : ()=>({
    total:{
      funn:{
        card:{
          profit: 0,
          sum: 0,
          sumVat: 0,
        },
        petrol:{
          sum: 0,
          sumVat: 0,
        }
      },
      circleK:{
        card:{
          profit: 0,
          sum: 0,
          sumVat: 0,
        },
        petrol:{
          sum: 0,
          sumVat: 0,
        }
      }
    }
  }),
  computed : {
    calc : ()=>{
      return calc;
    },
    colors:{ 
      get:function(){ return this.$store.getters.GET_COLORS }
    },
  },
  watch:{
    cards:{
      deep:true,
      handler(){
        this.calcCards(this.cards);
      }
    }
  },
  mounted(){
    this.calcCards(this.cards);
  },
  methods:{
    calcCards(cards){
      if(cards){
        if(cards.length>0){

          this.total={
            funn:{
              card:{
                profit: 0,
                sum: 0,
                sumVat: 0,
              },
              petrol:{
                sum: 0,
                sumVat: 0,
              }
            },
            circleK:{
              card:{
                profit: 0,
                sum: 0,
                sumVat: 0,
              },
              petrol:{
                sum: 0,
                sumVat: 0,
              }
            }
          }

          cards.map(card=>{
            if(card.client_id.includes('A')){ // FUNN
              if(this.book.type==1){
                this.total.funn.petrol.sum += this.calc.calcSumAmonutNET(card);
                this.total.funn.petrol.sumVat += this.calc.calcSumAmonutDiscountPrice(card);

                this.total.funn.card.profit += this.calc.calcSumCardAmonut(card);
                this.total.funn.card.sum += this.calc.calcSumAmonut(card);
                this.total.funn.card.sumVat += this.calc.calcSumAmonutVAT(card);
              } else if(this.book.type==2) {
                this.total.funn.petrol.sum += this.calc.calcSumAmonutNET(card);
                this.total.funn.petrol.sumVat += this.calc.calcSumAmonutDiscountVAT(card);

                this.total.funn.card.profit += this.calc.calcSumCardAmonut(card);
                this.total.funn.card.sum += this.calc.calcSumAmonut(card);
                this.total.funn.card.sumVat += this.calc.calcSumAmonutVAT(card);
              }
            } else {
              if(this.book.type==1){
                this.total.circleK.petrol.sum += this.calc.calcSumAmonutNET(card);
                this.total.circleK.petrol.sumVat += this.calc.calcSumAmonutDiscountPrice(card);
                
                this.total.circleK.card.profit += this.calc.calcSumCardAmonut(card);
                this.total.circleK.card.sum += this.calc.calcSumAmonut(card);
                this.total.circleK.card.sumVat += this.calc.calcSumAmonutVAT(card);
              } else if(this.book.type==2) {
                this.total.funn.petrol.sum += this.calc.calcSumAmonutNET(card);
                this.total.funn.petrol.sumVat += this.calc.calcSumAmonutDiscountVAT(card);

                this.total.funn.card.profit += this.calc.calcSumCardAmonut(card);
                this.total.funn.card.sum += this.calc.calcSumAmonut(card);
                this.total.funn.card.sumVat += this.calc.calcSumAmonutVAT(card);
              }
              
            }
            
          })
        }
      }
    },
    hasFUNN(){
      let temp = [];
      this.cards.map(card=>{
        if(card.client_id.includes('A')){ // FUNN
          temp.push(1);
        }
      })
      return temp.length;
    },
    hasCircleK(){
      let temp = [];
      this.cards.map(card=>{
        if(!card.client_id.includes('A')){ // FUNN
          temp.push(1);
        }
      })
      return temp.length;
    }
    
  }
}
</script>

<style>

</style>