<template>
  <div>
    <v-textarea
      v-model="company.contacts"
      rows="2"
      label="Contacs"
      outlined dense hide-details
    ></v-textarea>
  </div>
</template>

<script>
export default {
  name : 'Contact',
  props : ['company']
}
</script>

<style>

</style>