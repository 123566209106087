export default
{
	state: {
    baseUser: {},
    modal_baseUser:{
      create : false,
      edit : false,
      delete : false,
    },
    baseUsers:[],
  },
  mutations: {
    SET_BASEUSER(state,payload){
      state.baseUser=payload.baseUser;
    },
    UNSET_BASEUSER(state){
      state.baseUser={};
    },
    SET_MODAL_BASEUSER(state,payload){
      for(let i=0; i<Object.keys(payload).length; i++)
      {
        for(let j=0; j<Object.keys(state.modal_baseUser).length; j++)
        {
          if(Object.keys(payload)[i]==Object.keys(state.modal_baseUser)[j])
          {
            switch(Object.keys(state.modal_baseUser)[j])
            {
                case 'create': state.modal_baseUser.create=payload.create; break;
                case 'edit': state.modal_baseUser.edit=payload.edit; break;
                case 'delete': state.modal_baseUser.delete=payload.delete; break;
                default: console.log('Error');
            }
          }
        }
      }
    },
    UNSET_MODAL_BASEUSER(state){
      state.modal_baseUser = {
        create : false,
        edit : false,
        delete : false,
      }
    },
    SET_BASEUSERS(state,payload)
    {
      state.baseUsers=payload.baseUsers;
    },
    UNSET_BASEUSERS(state)
    {
      state.baseUsers=[];
    },
  },
  actions: {
    async CREATE_BASEUSER({commit, dispatch, getters }, payload){
      await axios.post(getters.GET_SERVER+'/api/users/create', {
        baseUser : getters.GET_BASEUSER
      })
      .then(response=> {
        if( response.data.status ){
          commit('UNSET_BASEUSER');
          commit('SET_MODAL_BASEUSER',{create:false});
          dispatch('LOAD_BASEUSERS');
        } else {
          this._vm.$toast.error({title:"Ошибка", message: response.data.message});
        }
      })
      .catch(err=>{
        this._vm.$toast.error({title:"Ошибка", message: err.message});
      });
    },
    async LOAD_BASEUSERS({commit, dispatch, getters }, payload){
      await axios.get(getters.GET_SERVER+'/api/users/show?page='+getters.GET_BASEUSERS_PAGINATION.page+'&per_page='+getters.GET_BASEUSERS_PAGINATION.per_page)
      .then(function (response) {
        commit('SET_BASEUSERS',{baseUsers:response.data.users});
        let pagination = getters.GET_BASEUSERS_PAGINATION;
        pagination.total=response.data.total;
        commit('SET_BASEUSERS_PAGINATION',{baseUsers_pagination:pagination});
      })
      .catch(err=>{
        this._vm.$toast.error({title:err.response.data.message});
      });
    },
    async EDIT_BASEUSER({commit, dispatch, getters }, payload){
      await axios.post(getters.GET_SERVER+'/api/users/edit/'+payload.baseUser.id, {
        baseUser: payload.baseUser
      })
      .then(response=> {
        if( response.data.status ){
          commit('SET_MODAL_BASEUSER',{edit:false});
          dispatch('LOAD_BASEUSERS');
        } else {
          this._vm.$toast.error({title:"Ошибка", message: response.data.message});
        }
      })
      .catch(err=>{
        this._vm.$toast.error({title:"Ошибка", message: err.message});
      });
    },
    async DELETE_BASEUSER({commit, dispatch, getters }, payload){
      await axios.post(getters.GET_SERVER+'/api/users/delete/'+payload.baseUser.id, {
        baseUser: payload.baseUser
      })
      .then(response=> {
        if( response.data.status ){
          commit('SET_MODAL_BASEUSER',{delete:false})
          dispatch('LOAD_BASEUSERS');
        } else {
          this._vm.$toast.error({title:"Ошибка", message: "Повторите запрос"});
        }
      })
      .catch(err=>{
        this._vm.$toast.error({title:"Ошибка", message: err.message});
      });
    },
    
  },
  getters:{
    GET_BASEUSER: (state)=> state.baseUser,
    GET_MODAL_BASEUSER: (state)=> state.modal_baseUser,
    GET_BASEUSERS: (state)=> state.baseUsers,
  }
}