<template>
  <div>
    <v-row>
      <v-col style="padding-bottom:0px;">
        <v-simple-table dense>
          <template v-slot:default>
            <tbody>
              <tr>
                <td class="pa-0">
                  <v-text-field
                    v-model="searchCompany.id"
                    dense outlined hide-details
                    disabled
                  ></v-text-field>
                </td>
                <td class="pa-0">
                  <v-text-field
                    v-model="searchCompany.code"
                    dense outlined hide-details
                  ></v-text-field>
                </td>
                <td class="pa-0">
                  <v-text-field
                    v-model="searchCompany.coef"
                    dense outlined hide-details
                  ></v-text-field>
                </td>
                <td class="pa-0">
                  <v-text-field
                    v-model="searchCompany.name"
                    dense outlined hide-details
                  ></v-text-field>
                </td>
                <td class="pa-0">
                  <v-text-field
                    v-model="searchCompany.address"
                    dense outlined hide-details
                  ></v-text-field>
                </td>
                <td class="pa-0">
                  <v-text-field
                    v-model="searchCompany.requisites"
                    dense outlined hide-details
                  ></v-text-field>
                </td>
                <td class="pa-0">
                  <v-text-field
                    v-model="searchCompany.comment"
                    dense outlined hide-details
                  ></v-text-field>
                </td>
                <td class="pa-0">
                  <v-text-field
                    v-model="searchCompany.emails"
                    dense outlined hide-details
                  ></v-text-field>
                </td>
                <td class="pa-0" align="center">
                  <v-btn
                    class="ma-1"
                    color="success"
                    icon
                    @click="doSearch()"
                  >
                    <v-icon>mdi-magnify-expand</v-icon>
                  </v-btn>
                  <v-btn
                    class="ma-1"
                    color="default"
                    icon
                    @click="clearSearch()"
                  >
                    <v-icon>mdi-close-box-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr :style="{'background-color': colors.table}">
                <th class="text-left" style="color:white;">
                  #
                </th>
                <th class="text-left" style="color:white;">
                  Code
                </th>
                <th class="text-left" style="color:white;">
                  Coef.
                </th>
                <th class="text-left" style="color:white;">
                  Name
                </th>
                <th class="text-left" style="color:white;">
                  Address
                </th>
                <th class="text-left" style="color:white;">
                  Requisites
                </th>
                <th class="text-left" style="color:white;">
                  Comments
                </th>
                <th class="text-left" style="color:white;">
                  Emails
                </th>
                <th class="text-left" style="color:white;">
                  Actions
                </th>
              </tr>
            </tbody>
            <tbody>
              <tr
                v-for="(company,index) in companies" :key="index"
              >
                <td class="brdr" style="max-width:50px; width:50px;">
                  {{index+1}}
                </td>
                <td class="brdr" style="max-width:80px; width:80px; min-width:80px;">
                  {{company.code}}
                </td>
                <td class="brdr" style="max-width:80px; width:80px;">
                  {{company.coef}}
                </td>
                <td class="ell brdr" style="max-width:150px; width:100px;">
                  {{company.name}}
                </td>
                <td class="ell brdr" style="max-width:100px; width:100px;">
                  {{company.address}}
                </td>
                <td class="ell brdr" style="max-width:100px; width:100px;">
                  {{company.requisites}}
                </td>
                <td class="ell brdr" style="max-width:80px; width:80px;">
                  {{company.comment}}
                </td>
                <td class="ell brdr" style="max-width:80px; width:80px;">
                  {{company.emails}}
                </td>
                <td class="brdr" style="max-width:100px; width:100px; min-width:100px;">
                  <v-row>
                    <v-col  align="center" class="pa-0">
                      <v-btn
                        icon
                        color="warning"
                        @click="edit_company(index, company.id)"
                      >
                        <v-icon>mdi-circle-edit-outline</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col  align="center" class="pa-0">
                      <v-btn
                        icon
                        color="error"
                        @click="delete_production(index, company.id)"
                      >
                        <v-icon>mdi-delete-circle-outline</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr :style="{'background-color': colors.table}">
                <td colspan="100"></td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
        <v-dialog 
          max-width="1200"
          v-model="modal_company.edit"
          scrollable
        >
          <v-card>
            <v-card-title 
              class="headline"
              style="background-color: #fb8c00; color:white;"
            >
              Edit Company ID: {{editCompany.id}}
            </v-card-title>
            <v-card-text style="padding:0px;">
              <v-container>
                <v-row>
                  <v-col style="padding: 0px 16px 16px 16px;">
                    <v-tabs>
                      <v-tab>
                        Company
                      </v-tab>
                      <v-tab>
                        Cards
                      </v-tab>
                      <v-tab-item>
                        <v-card flat>
                          <v-card-text style="padding:16px 0px 0px 0px;">
                            <Company :company="editCompany" />
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item>
                        <v-card flat>
                          <v-card-text style="padding:16px 0px 0px 0px;">
                            <Cards :company="editCompany" />
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                    </v-tabs>
                  </v-col>
                </v-row>
              </v-container>
                
              
            </v-card-text>
            
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                color="red darken-1"
                text
                @click="modal_company.edit=false"
              >
                Close
              </v-btn>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-btn
                color="warning darken-1"
                text
                @click="save_edit_request()"
              >
                Edit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog 
          max-width="1200"
          v-model="modal_company.delete"
          scrollable
        >
          <v-card>
            <v-card-title 
              class="headline"
              style="background-color: #ff5252; color:white;"
            >
              Delete Company ID: {{deleteCompany.id}}
            </v-card-title>
            <v-card-text style="padding:0px;">
              <v-container>
                <v-row>
                  <v-col>
                    Delete Company {{deleteCompany.name}} ?
                  </v-col>
                </v-row>
              </v-container>
                
              
            </v-card-text>
            
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                color="red darken-1"
                text
                @click="modal_company.delete=false"
              >
                Close
              </v-btn>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                text
                @click="save_delete_request()"
              >
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col style="padding:0px;">
        <Pagination :pagination="pagination" @load="loadCompanies()"/>
      </v-col>
    </v-row>
    
  </div>
</template>

<script>
import Pagination from '@/components/layouts/pagination/PaginationComponent.vue'
import Company from '@/components/companies/layout/Company.vue'
import Cards from '@/components/companies/layout/cards/CardsTable.vue'
export default {
  name : 'Companies',
  props: [ 'subcompany' ],
  components:{
    Company, Pagination, Cards
  },
  data: ()=>({
    companies:{},
    searchCompany:{},
    pagination:{ total:1, page:1, per_page:10 },
    editCompany:{},
    deleteCompany:{},
    modal_company:{create : false, edit : false, delete : false,}
  }),
  computed : {
    colors:{ 
      get:function(){ return this.$store.getters.GET_COLORS }
    },
  },
  mounted(){
    this.loadCompanies();
  },
  methods:{
    doSearch(){
      this.loadCompanies();
    },
    clearSearch(){
      this.searchCompany={};
      this.loadCompanies();
    },
    async loadCompanies(){
      let searchs=this.searchCompany;
      if(Object.keys(searchs).length!=0){
        let result='';
        Object.keys(searchs).map(search=>{
          if(!searchs[search]){
            delete searchs[search];
          }
          result +=`&${search}=`+searchs[search];
        })
        searchs=result;
      } else { searchs=''}
      await axios.get(this.$store.getters.GET_SERVER+'/api/companies/show?page='+this.pagination.page+'&per_page='+this.pagination.per_page+'&subcompanyId='+this.subcompany+searchs)
      .then((response) => {
        this.companies = response.data.companies;
        this.pagination.total=response.data.total;
      })
      .catch(err=>{
        console.log(err)
        this.$toast.error({title:err.response.data.message});
      });
    },
    format_date(_date){ return new Date(_date).toLocaleDateString("ru-RU"); },
    type(type){
      return type==0 ? 'Клиент' : 'Перевозчик';
    },
    edit_company(index, id){
      if( this.companies[index].id == id ){
        this.editCompany=Object.assign({}, this.companies[index]);
        this.modal_company.edit = true;
      }
      
    },
    check_request(company){
      let errors = [];
      // if(!this.company.type && this.company.type!=0){ errors.push("Тип пусто!") }
      if(!company.name){ errors.push("Наименование пусто!") }
      // if(!this.company.address){ errors.push("Адрес пусто!") }
      // if(!this.company.tin){ errors.push("ИНН пусто!") }
      // if(!this.company.requisites){ errors.push("Реквизиты пусто!") }
      // if(!this.company.comment){ errors.push("Комментарии пусто!") }
      // if(!this.company.director){ errors.push("Директор пусто!") }
      return errors;
    },
    save_edit_request(){
      let errors = this.check_request(this.editCompany);
      if( errors.length==0 ){
        this.$store.dispatch('EDIT_COMPANY', {company:this.editCompany}).then(()=>{
          this.loadCompanies();
          this.modal_company.edit=false;
        });
      } else {
        for(let i=0; i<errors.length; i++){
          this.$toast.error({title:"Изменение Компании", message: errors[i]})
        }
      }
    },
    delete_production(index, id){
      if( this.companies[index].id == id ){
        this.deleteCompany=Object.assign({}, this.companies[index]);
        this.modal_company.delete = true;
      }
    },
    save_delete_request(){
      this.$store.dispatch('DELETE_COMPANY', {company:this.deleteCompany}).then(()=>{
        this.loadCompanies();
        this.modal_company.delete=false;
      });
    }
  }
}
</script>

<style>

</style>