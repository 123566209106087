<template>
  <div>
    <v-dialog 
      max-width="1200"
      v-model="modal_company.edit"
      scrollable
    >
      <v-card>
        <v-card-title 
          class="headline"
          style="background-color: #fb8c00; color:white;"
        >
          Edit Company ID: {{company.id}}
        </v-card-title>
        <v-card-text style="padding:0px;">
          <v-container>
            <v-row>
              <v-col style="padding: 0px 16px 16px 16px;">
                <v-tabs>
                  <v-tab>
                    Company
                  </v-tab>
                  <v-tab>
                    Cards
                  </v-tab>
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text style="padding:16px 0px 0px 0px;">
                        <Company :company="company" />
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text style="padding:16px 0px 0px 0px;">
                        <Cards :company="company" />
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </v-col>
            </v-row>
          </v-container>
            
          
        </v-card-text>
        
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            @click="modal_company.edit=false"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn
            color="warning darken-1"
            text
            @click="save_edit_request()"
          >
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Company from '@/components/companies/layout/Company.vue'
import Cards from '@/components/companies/layout/cards/CardsTable.vue'
export default {
  name : 'EditComponent',
  props : ['company'],
  components : { Company, Cards },
  computed : {
    modal_company:{ 
      get:function(){ return this.$store.getters.GET_MODAL_COMPANY },
      set:function(value){ return this.$store.commit('SET_MODAL_COMPANY',{modal_company:value}) }
    },
  },
  methods:{
    save_edit_request(){
      let errors = this.check_edit_request();
      if( errors.length==0 ){
        this.$store.dispatch('EDIT_COMPANY', {company:this.company}).then(()=>{
          this.$store.commit('SET_MODAL_COMPANY',{edit:false})
          this.$store.dispatch('LOAD_COMPANIES', {subcompany: this.company.subcompanyId});
        });
      } else {
        for(let i=0; i<errors.length; i++){
          this.$toast.error({title:"Изменение Компании", message: errors[i]})
        }
      }
    },
    check_edit_request(){
      let errors = [];
      // if(!this.company.type && this.company.type!=0){ errors.push("Тип пусто!") }
      if(!this.company.name){ errors.push("Наименование пусто!") }
      // if(!this.company.address){ errors.push("Адрес пусто!") }
      // if(!this.company.tin){ errors.push("ИНН пусто!") }
      // if(!this.company.requisites){ errors.push("Реквизиты пусто!") }
      // if(!this.company.comment){ errors.push("Комментарии пусто!") }
      // if(!this.company.director){ errors.push("Директор пусто!") }
      return errors;
    }
  }
}
</script>

<style>

</style>