<template>
  <div>
    <v-row>
      <v-col>
        <Name :numbering="numbering" />        
      </v-col>
      <v-divider vertical></v-divider>
      <v-col>
        <Split :numbering="numbering" />
      </v-col>
    </v-row>
    <v-row>
      <v-col style="padding:0px;">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <Prefix :numbering="numbering" />
      </v-col>
      <v-col>
        <Index :numbering="numbering" />
      </v-col>
      <v-col>
        <Suffix :numbering="numbering" />
      </v-col>
    </v-row>
    <v-row>
      <v-col style="padding:0px;">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <Count :numbering="numbering" />
      </v-col>
      <v-divider vertical></v-divider>
      <v-col>
        <Previewer :numbering="numbering" />
      </v-col>
    </v-row>
    <v-row>
      <v-col style="padding:0px;">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <Comment :numbering="numbering" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Name from './Name.vue'
import Comment from './Comment.vue'
import Split from './Split.vue'
import Prefix from './Prefix.vue'
import Index from './Index.vue'
import Suffix from './Suffix.vue'
import Count from './Count.vue'
import Previewer from './Previewer.vue'

export default {
  props : ['numbering'],
  components: { Name, Comment, Split, Prefix, Index, Suffix, Count, Previewer },
}
</script>

<style>

</style>