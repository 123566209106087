<template>
  <div>
    <v-row>
      <v-col cols="3">
        Dates
      </v-col>
      <v-col>
        <v-text-field
          v-model="card.paydate"
          type="date"
          dense outlined hide-details
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="card.payup"
          type="date"
          dense outlined hide-details
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props : ['card'],
  data:()=>({
    
  }),
  methods : {
    
  }
}
</script>

<style>

</style>