<template>
  <div>
    <v-dialog 
      max-width="1000"
      v-model="modal_book.pdfWidgets"
      scrollable
    >
      <v-card>
        <v-card-title 
          class="headline"
          :style="{'background-color': '#fb8c00'}"
          style="color:white;"
        >
          Card ID: {{card.client_id}} - {{getCompany(card.client_id)}} | Download PDF
        </v-card-title>
        <v-card-text style="padding:0px;">
          <v-container>
            <v-row>
              <v-col class="pb-0">
                <SubCompanyWidgets :card="card" />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0">
                <NumberWidgets :card="card" />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0">
                <DatesWidgets :card="card" />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0">
                <MonthWidgets :card="card" />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0">
                <NumWordsWidgets :card="card" />
              </v-col>
            </v-row>
          </v-container>
          
        </v-card-text>
        
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            @click="modal_book.pdfWidgets=false"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-1"
            text
            @click="pdf_down()"
          >
            Download
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import NumberWidgets from './Number.vue';
import DatesWidgets from './Dates.vue';
import MonthWidgets from './Month.vue';
import NumWordsWidgets from './NumWords.vue';
import SubCompanyWidgets from './SubCompany.vue';
export default {
  props : ['card'],
  components : { NumberWidgets, DatesWidgets, MonthWidgets, NumWordsWidgets, SubCompanyWidgets },
  computed : {
    book : { 
      get:function(){ return this.$store.getters.GET_BOOK },
      set:function(value){ return this.$store.commit('SET_BOOK',{book:value}) }
    },
    modal_book : { 
      get:function(){ return this.$store.getters.GET_MODAL_BOOK },
      set:function(value){ return this.$store.commit('SET_MODAL_BOOK',{modal_book:value}) }
    },
    colors:{ 
      get:function(){ return this.$store.getters.GET_COLORS }
    },
    companies : {
      get:function(){ return this.$store.getters.GET_COMPANIES },
      set:function(value){ return this.$store.commit('SET_COMPANIES',{companies:value}) }
    },
   
  },
  mounted(){

  },
  methods : {
    getCompany(id){
      let companies = this.companies.filter(company=>company.subcompanyId==this.book.subcompany)
      for(let i=0; i<companies.length; i++){
        if(companies[i].code == id){
          return companies[i].name;
          break;
        }
      }
    },
    pdf_down(){
      let errors = this.check_pdf();
      if(errors.length==0){
        let cardPDF = {
          book_id : this.card.book_id,
          card_id : this.card.card_id,
          client_id : this.card.client_id,
          subcompany : this.card.subcompany,
          number : this.card.preview,
          numbering_id : this.card.numbering_id,
          index : this.card.index,
          paydate : this.card.paydate,
          payup : this.card.payup,
          month : this.card.month,
          sum : this.card.sum,
          sum_vat : this.card.sum_vat,
          comment : this.card.comment,
          sum_en : this.card.sum_en,
          sum_lt : this.card.sum_lt,
        }
        this.$store.dispatch("PDF_CARD_DOWN",{vm:this, card:cardPDF}).then(()=>{
          this.modal_book.pdfWidgets=false;
        })
      } else {
        errors.map((err)=>{
          this.$toast.error({title:"Error",message:err});
        })
      }
      
    },
    check_pdf(){
      let error=[];
      if(!this.card.subcompany){error.push("Sub Company is empty")}
      if(!this.card.preview){error.push("Document number is empty")}
      if(!this.card.paydate){error.push("Invoice date is empty")}
      if(!this.card.payup){error.push("Pay up date is empty")}
      if(!this.card.month){error.push("Month is empty")}
      if(!this.card.sum){error.push("Sum is empty")}
      if(!this.card.sum_en){error.push("Sum in words in English empty")}
      if(!this.card.sum_lt){error.push("Sum in words in Lithuanian empty")}
      return error;
    }
  }
}
</script>

<style>

</style>