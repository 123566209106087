let calcMails = (cards, statuses) => {
  statuses.notSent = 0; statuses.success = 0; statuses.fail = 0;
  cards.map(card=>{
    if(card.send){
      statuses.success += 1;
    } else if(card.send === null){
      statuses.notSent += 1;
    } else if(card.send === 0){
      statuses.fail += 1;
    }
  })
  return statuses;
}

module.exports = {
  calcMails
}