<template>
  <div>
    <v-textarea
      v-model="company.emails"
      rows="2"
      label="Emails"
      outlined dense hide-details
    ></v-textarea>
  </div>
</template>

<script>
export default {
  props : ['company']
}
</script>

<style>

</style>