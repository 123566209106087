export default
{
  state: {
    company: {},
    modal_company:{
      create : false,
      edit : false,
      delete : false,
    },
    companies:[],
    searchCompany:{}
  },
  mutations: {
    SET_COMPANY(state,payload)
    {
      state.company=payload.company;
    },
    UNSET_COMPANY(state)
    {
      state.company={};
    },
    SET_MODAL_COMPANY(state, payload){
      for(let i=0; i<Object.keys(payload).length; i++)
      {
        for(let j=0; j<Object.keys(state.modal_company).length; j++)
        {
          if(Object.keys(payload)[i]==Object.keys(state.modal_company)[j])
          {
            switch(Object.keys(state.modal_company)[j])
            {
                case 'create': state.modal_company.create=payload.create; break;
                case 'edit': state.modal_company.edit=payload.edit; break;
                case 'delete': state.modal_company.delete=payload.delete; break;
                default: console.log('Error');
            }
          }
        }
      }
    },
    UNSET_MODAL_COMPANY(state){
      state.modal_company = {
        create : false,
        edit : false,
        delete : false,
      }
    },
    SET_COMPANIES(state,payload){ state.companies=payload.companies; },
    UNSET_COMPANIES(state){ state.companies=[]; },
    SET_SEARCHCOMPANY(state,payload){ state.searchCompany=payload.searchCompany; },
    UNSET_SEARCHCOMPANY(state){ state.searchCompany={}; },
  },
  actions:{
    async CREATE_COMPANY({commit, dispatch, getters }, payload){
      let subcompany = getters.GET_COMPANY.subcompanyId;
      await axios.post(getters.GET_SERVER+'/api/companies/create', {
        company: getters.GET_COMPANY
      })
    },
    async EDIT_COMPANY({commit, dispatch, getters }, payload){
      await axios.post(getters.GET_SERVER+'/api/companies/edit/'+payload.company.id, {
        company: payload.company
      })
    },
    async DELETE_COMPANY({commit, dispatch, getters }, payload){
      await axios.post(getters.GET_SERVER+'/api/companies/delete/'+payload.company.id, {
        company: payload.company
      })
    },
    async LOAD_COMPANIES({commit, dispatch, getters }, payload){
      let searchs=getters.GET_SEARCHCOMPANY;
      if(Object.keys(searchs).length!=0){
        let result='';
        Object.keys(searchs).map(search=>{
          if(!searchs[search]){
            delete searchs[search];
          }
          result +=`&${search}=`+searchs[search];
        })
        searchs=result;
      } else { searchs=''}
      
      await axios.get(getters.GET_SERVER+'/api/companies/show?page='+getters.GET_COMPANIES_PAGINATION.page+'&per_page='+getters.GET_COMPANIES_PAGINATION.per_page+'&subcompanyId='+payload.subcompany+searchs)
      .then(function (response) {
        commit('SET_COMPANIES',{companies:response.data.companies});
        let pagination = getters.GET_COMPANIES_PAGINATION;
        pagination.total=response.data.total;
        commit('SET_COMPANIES_PAGINATION',{companies_pagination:pagination});
      })
      .catch(err=>{
        this._vm.$toast.error({title:err.response.data.message});
      });
    },
    async LOAD_AUTOCOMPLETE_COMPANY({commit, dispatch, getters }, payload){
      let type = payload.type || payload.type===0 ? '&type='+payload.type : ''
      await axios.get(getters.GET_SERVER+'/api/companies/autocomplete?company='+payload.name+type)
      .then(function (response) {
        if(response.data.status){
          commit('SET_COMPANIES',{companies:response.data.companies});
        } else{
          // this._vm.$toast.error({title:"Ошибка", message: "Повторите запрос"});
        }
      })
    },
    async LOAD_BASE_COMPANIES({commit, dispatch, getters }, payload){
      await axios.post(getters.GET_SERVER+'/api/companies/base', {
      })
      .then(response=> {
        if( response.data.status ){
          commit('SET_SUBCOMPANIES',{subcompanies:response.data.subcompanies})
        } else {
          payload.vm.$toast.error({title:"Ошибка", message: response.data.message});
        }
      })
      .catch(err=>{
        payload.vm.$toast.error({title:"Ошибка", message: err.message});
      });
    },
    
  },
  getters:{
    GET_COMPANY:(state)=>state.company,
    GET_MODAL_COMPANY:(state)=>state.modal_company,
    GET_COMPANIES:(state)=>state.companies,
    GET_SEARCHCOMPANY:(state)=>state.searchCompany,
  }
}