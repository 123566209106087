export default
{
  state: {
    colors:{
      header:'#1976d2',
      table:'#1976d2',
      background:'#ffffff', 
    },
    modal_colors:false,
  },
  mutations: {
    SET_COLORS(state, payload){
      for(let i=0; i<Object.keys(payload).length; i++)
      {
        for(let j=0; j<Object.keys(state.colors).length; j++)
        {
          if(Object.keys(payload)[i]==Object.keys(state.colors)[j])
          {
            switch(Object.keys(state.colors)[j])
            {
                case 'header': state.colors.header=payload.header; break;
                case 'table': state.colors.table=payload.table; break;
                case 'background': state.colors.background=payload.background; break;
                default: console.log('Error');
            }
          }
        }
      }
    },
    UNSET_COLORS(state){
      state.colors.header='#1976d2';
      state.colors.table='#1976d2';
      state.colors.background='#ffffff';
    },
    SET_MODAL_COLORS(state, payload){
      state.modal_colors=payload.modal_colors;
    },
    UNSET_MODAL_COLORS(state){
      state.modal_colors=false;
    }
  },
  actions:{

  },
  getters:{
    GET_COLORS:(state)=>state.colors,
    GET_MODAL_COLORS:(state)=>state.modal_colors,
  }
}