<template>
  <div>
    <v-container>
      <v-row>
        <v-col align="end" style="padding-top:0px;">
          <v-btn
            small
            color="green accent-3"
            @click="addNumbering()"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <v-col align="center" style="padding-top:0px;">
          <h2>Numbering</h2>
        </v-col>
        <v-col style="padding-top:0px;">
          <v-btn
            small
            color="cyan accent-3"
            @click="reloadNumberings()"
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <CreateNumbering />
  </div>
</template>

<script>
import CreateNumbering from './create/CreateNumbering.vue';
export default {
  name : 'Widgets',
  components: { CreateNumbering },
  computed: {
    modal_numbering:{ 
      get:function(){ return this.$store.getters.GET_MODAL_NUMBERING },
      set:function(value){ return this.$store.commit('SET_MODAL_NUMBERING ',{modal_numbering:value}) }
    },
  },
  methods:{
    addNumbering(){
      this.modal_numbering.create=true;
    },
    reloadNumberings(){
      this.$store.dispatch('LOAD_NUMBERINGS')
    }
  }
}
</script>

<style>

</style>