export default
{
  state: {
    navbar_items:[]
  },
  mutations: {
    SET_NAVBAR_ITEMS(state, payload){
      state.navbar_items=payload.navbar_items;
    },
    UNSET_NAVBAR_ITEMS(state){
      state.navbar_items=[];
    }
  },
  actions:{
    async LOAD_NAVBAR_ITEMS({commit, dispatch, getters }, payload){
      await axios.post(getters.GET_SERVER+'/api/users/items')
      .then(function (response) {
        commit('SET_NAVBAR_ITEMS', { navbar_items: response.data.navbar_items })
      })
    },
  },
  getters:{
    GET_NAVBAR_ITEMS: (state) => state.navbar_items,

  }
}