<template>
  <div>
    <Widgets />
    <Table />
    <Pagination />
  </div>
</template>

<script>
import Widgets from './Widgets.vue';
import Table from './table/NumberingTable.vue'
import Pagination from './pagination/NumberingPagination.vue'
export default {
  components: { Widgets, Table, Pagination },
  mounted(){
    
  },
  methods:{
  }
}
</script>

<style>

</style>