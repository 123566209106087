<template>
  <div>
    <v-row>
      <v-col cols="3">
        Month
      </v-col>
      <v-col cols="9">
        <v-text-field
          v-model="card.month"
          type="number"
          max="12" min="1"
          dense outlined hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    
  </div>
</template>

<script>
export default {
  props : ['card']
}
</script>

<style>

</style>