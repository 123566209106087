<template>
  <div>
    <Widgets />
    <Table />
    <Pagination />
  </div>
</template>

<script>
import Widgets from './Widgets.vue';
import Table from './table/UsersTable.vue'
import Pagination from './pagination/UsersPagination.vue'
export default {
  name : 'Users',
  components: { Widgets, Table, Pagination },

}
</script>

<style>

</style>