
let hundToEN = (input, addAnd, EN)=>{
  while(input.slice(0,1) == 0 && input.length != 1){
    input = input.slice(1);  
  }
  input += '';
  let str = '';
  if(input.length == 1){
    str += (input == 0 ? '' : ((addAnd) ? 'and ' + EN[input] :  EN[input]));
    return str;
  }
  if(input.length ==2){
    if(Object.keys(EN).includes(input)){
      str += EN[input];
      return str;
    }
    str += EN[input[0] + '0'].slice(0,-1) + '-' + EN[input[1]];
    return str;
  }

  str += EN[input[0]] + 'hundred ';
  if(Object.keys(EN).includes(input.slice(1))){
    return str + EN[input.slice(1)];
  }
  if(input[1] == 0){
    return str + (input[2] == 0 ? '' : 'and ' + EN[input[2]]);
  }
  return str + EN[input[1] + '0'].slice(0,-1) + '-' + EN[input[2]];
};
let hundToLT = (input, LT) =>{
  if(input[0] == 0){
    input = input.slice(1);
  }
  let str = '';
  if(input.length == 3){
    if(input[0] == 1){
      str += LT[input[0]] + 'šimtas ';
    }else{
      str += LT[input[0]] + 'šimtai ';
    }
    input = input.slice(1);  
  }
  if(input[0] == 0){
    input = input.slice(1);
  }
  if(input.length == 2){
    if(input[0] == 1){
      str += LT[input];
      return str;
    }
    str += LT[input[0] + '0'];
    input = input.slice(1);
    if(input[0] == 0){
      return str;
    }
    return str + LT[input[0]];
  }
  str += LT[input];  
  return str;
}
let numLT = (input) => {
  let LT = {    
    0 : "nulis ", 1 : "vienas ", 2 : "du ", 3 : "trys ",
    4 : "keturi ", 5 : "penki ", 6 : "šeši ", 7 : "septyni ",
    8 : "aštuoni ", 9 : "devyni ", 10 : "dešimt ", 11 : "vienuolika ",
    12 : "dvylika ", 13 : "trylika ", 14 : "keturiolika ", 15 : "penkiolika ",
    16 : "šešiolika ", 17 : "septyniolika ", 18 : "aštuoniolika ", 19 : "devyniolika ",
    20 : "dvidešimt ", 30 : "trisdešimt ", 40 : "keturiasdešimt ", 50 : "penkiasdešimt ",
    60 : "šešiasdešimt ", 70 : "septyniasdešimt ", 80 : "aštuoniasdešimt ", 90 : "devyniasdešimt "
  }
  input += '';
  let parts = input.split('.'); 
  let razryad = [];
  if(parts[0].length % 3 > 0){
    razryad.push(parts[0].slice(0, parts[0].length % 3));
    parts[0] = parts[0].slice(parts[0].length % 3)
  }else{
    razryad.push(parts[0].slice(0, 3));
    parts[0] = parts[0].slice(3);
  }
  for(; parts[0].length > 0; parts[0] = parts[0].slice(3)){
    razryad.push(parts[0].slice(0, 3));
  }
  let word = '';
  for(let i = 0; i < razryad.length; i++){
    if(razryad.length - i == 4){
      word += hundToLT(razryad[i], LT) + 'milijardų ';
    }
    if(razryad.length - i == 3){
      word += hundToLT(razryad[i], LT) + 'milijonų ';
    }
    if(razryad.length - i == 2){
      word += hundToLT(razryad[i], LT) + 'tūkstančiai ';
    }
    if(razryad.length - i == 1){
      word += hundToLT(razryad[i], LT);
    }
  }
  if(parts[1]){
  	if(parts[1].length > 2) parts[1] = parts[1].slice(0, 2);
  	if((parts[1] != 0) && (parts[1] != "00")){
  		word += "EUR ";
  	} else {
  		return word.slice(0,-1);
  	}
  	if(parts[1].length == 1) word += LT[parts[1] + "0"];
		if(parts[1].length == 2){
			if(parts[1][0] == 0){
				word += LT[parts[1][1]]
			}else{
				if(Object.keys(LT).includes(parts[1])) return word + LT[parts[1]] + ' ct.';
				word += (parts[1][1] == 0 ? LT[parts[1][0] + "0"] : LT[parts[1][0] + "0"].slice(0, -1) +  "-" + LT[parts[1][1]]);
			}
		}
  }
  return word.slice(0,-1) + ' ct.';
};
let numEN = (input) => {
  let EN = {
    0: "zero ", 1: "one ", 2:  "two ", 3: "three ",
    4: "four ", 5: "five ", 6: "six ", 7: "seven ",
    8: "eight ", 9: "nine ", 10: "ten ", 11: "eleven ",
    12: "twelve ", 13: "thirteen ", 14: "fourteen ", 15: "fifteen ",
    16: "sixteen ", 17: "seventeen ", 18: "eighteen ", 19: "nineteen ",
    20: "twenty ", 30: "thirty ", 40: "forty ", 50: "fifty ", 
    60: "sixty ", 70: "seventy ", 80: "eighty", 90: "ninety "
  }


  input += '';
  let parts = input.split('.'); 
  let razryad = [];
  if(parts[0].length % 3 > 0){
    razryad.push(parts[0].slice(0, parts[0].length % 3));
    parts[0] = parts[0].slice(parts[0].length % 3)
  }else{
    razryad.push(parts[0].slice(0, 3));
    parts[0] = parts[0].slice(3);
  }
  for(; parts[0].length > 0; parts[0] = parts[0].slice(3)){
    razryad.push(parts[0].slice(0, 3));
  }
  let word = '';
  for(let i = 0; i < razryad.length; i++){
    if(razryad.length - i == 4){
      word += hundToEN(razryad[i], false, EN) + 'billion ';
    }
    if(razryad.length - i == 3){
      word += hundToEN(razryad[i], false, EN) + 'million ';
    }
    if(razryad.length - i == 2){
      word += hundToEN(razryad[i], false, EN) + 'thousand ';
    }
    if(razryad.length - i == 1){
      if(razryad.length == 1){
        word += hundToEN(razryad[i], false, EN);
      }else{
        word += hundToEN(razryad[i], true, EN);
      }
    }
  }
  if(parts[1]){
  	if(parts[1].length > 2) parts[1] = parts[1].slice(0, 2);
  	if((parts[1] != 0) && (parts[1] != "00")){
  		word += "EUR ";
  	} else {
  		return word.slice(0,-1);
  	}
  	if(parts[1].length == 1) word += EN[parts[1] + "0"];
		if(parts[1].length == 2){
			if(parts[1][0] == 0){
				word += EN[parts[1][1]]
			}else{
				if(Object.keys(EN).includes(parts[1])) return word + EN[parts[1]] + ' ct.';
				word += (parts[1][1] == 0 ? EN[parts[1][0] + "0"] : EN[parts[1][0] + "0"].slice(0, -1) +  "-" + EN[parts[1][1]]);
			}
		}
  }
  return word.slice(0,-1) + ' ct.';
};

let calcCount = (index, cnt) =>{
  let count = Number(cnt)
  if(count>0){
    let currentSymbols = index.length;
    if(currentSymbols<count){
      let diff = count - currentSymbols;
      let temp='';
      for (let i = 0; i < diff; i++) {
        temp += '0';
      }
      index = temp + index
    }
  }
  return index;
};
let SumHTML = (payments) => {
  let sum = 0;
  payments.data.forEach(payment => {
    if(payment.overprice){
      sum += Number(payment.amount)*1.21;
    } else {
      sum += Number(payment.net)*1.21;
    }
  });
  return sum;
}

module.exports = {
  hundToEN, numEN,
  hundToLT, numLT,
  calcCount, SumHTML,
}