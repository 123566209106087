import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

import Admin from '../views/Admin.vue';
import MainBooks from '@/components/books/layout/Book.vue'
import CompaniesComponent from '@/components/companies/CompaniesComponent.vue'

import EditBooksComponent from '@/components/books/edit/EditBooksComponent.vue'
import BooksComponent from '@/components/books/BooksComponent.vue'

import BaseComponent from '@/components/base/BaseComponent.vue'
import UsersComponent from '@/components/base/users/UsersComponent.vue'
import SubCompaniesComponent from '@/components/base/subCompanies/SubCompanies.vue'
import NumberingComponent from '@/components/base/numbering/Numbering.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path : '/books',
    name : 'Books',
    component : BooksComponent,
    beforeEnter: checkUserRights,
  },
  {
    path : '/books/:id',
    name : 'BooksEdit',
    component : EditBooksComponent,
    beforeEnter: checkUserRights,
    children : [
      {
        path : '/books/:id/main',
        name : 'EditBookMain',
        component : MainBooks,
      },
    ]
  },
  {
    path : '/companies',
    name : 'Companies',
    component : CompaniesComponent,
    beforeEnter: checkUserRights,
  },
  {
    path: '/admin',
    name: 'Admin',
    beforeEnter: checkAdminRights,
    component: Admin,
    children: [
      {
        path : '/admin/base',
        name : 'AdminBase',
        component : BaseComponent,
        children: [
          {
            path : '/admin/base/users',
            name : 'AdminBaseUsers',
            component : UsersComponent
          },
          {
            path : '/admin/base/subcompanies',
            name : 'AdminBaseSubCompanies',
            component : SubCompaniesComponent
          },
          {
            path : '/admin/base/numbering',
            name : 'AdminBaseNumbering',
            component : NumberingComponent
          },
        ]
      },
    ]
  },
  {
    path: '*',
    redirect: to=>{
      if(!axios.defaults.headers.common['x-auth-token'])
      {
        if(!localStorage.getItem('token'))
        {
          store.dispatch('LOGOUT');
        }
        else{
          axios.defaults.headers.common['x-auth-token']=localStorage.getItem('token');
          router.go(-1);
        }
      }
      else{
        router.go(-1);
      }
      
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function checkAdminRights(to, from, next) {
  let token=localStorage.getItem('token');
  if(token){
    let user = parser(token);
    if(user){
      if(user.role=='admin') {
        next();       
      } else {
        console.log('adminCheck')
        router.go(-1);
      }
    }
  }
}
function checkUserRights(to, from, next){
  let token=localStorage.getItem('token');
  if(token){
    let user = parser(token);
    if(user){
      if(user.role=='admin' || user.role=='agent' || user.role=='manager') {
        next();       
      } else {
        console.log('UserCheck')
        router.go(-1);
      }
    }
  }
}

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && to.name !== 'Home' && !localStorage.getItem('token')){
    
    next({ name: 'Login' });
  } 
  else next();
})

function parser(token) {
  function first(){
    try{
      let base64Url = token.split('.')[0];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);  
    }
    catch{
      localStorage.clear();
      return false;
    }
  }
  function second(){
    try{
      let base64Url = token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);  
    }
    catch{
      localStorage.clear();
      return false;
    }
  }
  return first() && second();
};

export default router;
