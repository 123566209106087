export default
{
  state: {
    companies_pagination:{ total:1, page:1, per_page:10 },
    books_pagination:{ total:1, page:1, per_page:10 },
    baseUsers_pagination:{ total:1, page:1, per_page:10 },
    subcompanies_pagination:{ total:1, page:1, per_page:10 },
    numbering_pagination:{ total:1, page:1, per_page:10 },
  },
  mutations: {
    SET_COMPANIES_PAGINATION(state,payload){ state.companies_pagination=payload.companies_pagination; },
    UNSET_COMPANIES_PAGINATION(state){ state.companies_pagination={ total:1, page:1, per_page:10 }; },
    SET_BOOKS_PAGINATION(state,payload){ state.books_pagination=payload.books_pagination; },
    UNSET_BOOKS_PAGINATION(state){ state.books_pagination={ total:1, page:1, per_page:10 };},
    SET_BASEUSERS_PAGINATION(state,payload){ state.baseUsers_pagination=payload.baseUsers_pagination; },
    UNSET_BASEUSERS_PAGINATION(state){ state.baseUsers_pagination={ total:1, page:1, per_page:10 }; },
    SET_SUBCOMPANIES_PAGINATION(state,payload){ state.subcompanies_pagination=payload.subcompanies_pagination; },
    UNSET_SUBCOMPANIES_PAGINATION(state){ state.subcompanies_pagination={ total:1, page:1, per_page:10 }; },
    SET_NUMBERING_PAGINATION(state,payload){ state.numbering_pagination=payload.numbering_pagination; },
    UNSET_NUMBERING_PAGINATION(state){ state.numbering_pagination={ total:1, page:1, per_page:10 }; },
  },
  actions:{

  },
  getters:{
    GET_COMPANIES_PAGINATION: (state)=> state.companies_pagination,
    GET_BOOKS_PAGINATION: (state)=> state.books_pagination,
    GET_BASEUSERS_PAGINATION: (state)=> state.baseUsers_pagination,
    GET_SUBCOMPANIES_PAGINATION: (state)=> state.subcompanies_pagination,
    GET_NUMBERING_PAGINATION: (state)=> state.numbering_pagination,
  }
}