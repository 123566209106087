<template>
  <div>
    <v-row>
      <v-col style="padding:0px;">
        <Pagination :pagination="books_pagination" @load="loadBooks()"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Pagination from '@/components/layouts/pagination/PaginationComponent.vue'
export default {
  name : 'BooksPagination',
  components : { Pagination },
  computed : {
    books_pagination:{ 
      get:function(){ return this.$store.getters.GET_BOOKS_PAGINATION },
      set:function(value){ return this.$store.commit('SET_BOOKS_PAGINATION',{books_pagination:value}) }
    },
  },
  watch : {
    books_pagination:{
      handler(){
        this.$store.dispatch('LOAD_BOOKS');
      },
      deep:true
    }
  },
  methods:{
    loadBooks(){
      this.$store.dispatch('LOAD_BOOKS');
    }
  }
}
</script>

<style>

</style>