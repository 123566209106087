<template>
  <div>
    <v-row>
      <v-col>
        <v-textarea
          v-model="company.requisites"
          rows="6"
          label="Requisites"
          outlined dense hide-details
        ></v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name : 'Requisites',
  props : ['company']
}
</script>