<template>
  <div>
    
    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <v-select
              v-model="book.subcompany"
              :items="subcompanies"
              item-text="name"
              item-value="id"
              label="Sub Company"
              dense outlined hide-details
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="book.paydate"
              type="date"
              label="Invoice Date"
              dense outlined hide-details
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              v-model="book.month"
              :items="months"
              item-text="title"
              item-value="id"
              label="Month"
              dense outlined hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col>
        <v-row>
          <v-col>
            <v-file-input
              v-model="book.file"
              type="file"
              color="deep-purple accent-4"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              label="Excel input"
              placeholder="Select your excel file"
              prepend-icon="mdi-paperclip"
              outlined dense hide-details
              :show-size="1000"
            >
            </v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              color="info"
              block
              @click="uploadExcel()"
            >
              <v-icon style="margin-right:6px;">mdi-cloud-upload-outline</v-icon>
              Upload
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col>
        <Mail :book="book" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Mail from './Mail.vue';
import calc from './card/calc'
import { months } from './months'
export default {
  props : ['book'],
  components : { Mail },
  computed : {
    months : function(){
      return months
    },
    preview : { 
      get:function(){ return this.$store.getters.GET_PREVIEW },
      set:function(value){ return this.$store.commit('SET_PREVIEW',{preview:value}) }
    },
    savedPreview : { 
      get:function(){ return this.$store.getters.GET_SAVEDPREVIEW },
      set:function(value){ return this.$store.commit('SET_SAVEDPREVIEW',{savedPreview:value}) }
    },
    subcompanies : {
      get:function(){ return this.$store.getters.GET_SUBCOMPANIES },
      set:function(value){ return this.$store.commit('SET_SUBCOMPANIES',{subcompanies:value}) }
    },
    last_invoice : {
      get:function(){ return this.$store.getters.GET_LAST_INVOICE },
      set:function(value){ return this.$store.commit('SET_LAST_INVOICE',{last_invoice:value}) }
    },
  },
  data: () => ({
    card:{},
    pdfCard : {},
  }),
  methods:{
    uploadExcel(){
      if(this.book.file){
        this.$store.dispatch('UPLOAD_EXCEL', {vm:this, file:this.book.file, subcompanyId: this.book.subcompany})
      } else {
        this.$toast.error({title:"Error",message:"Select a file"})
      }
      
    },
  }
}
</script>

<style>

</style>