<template>
  <div>
    <v-container>
      <v-row>
        <v-col align="end" style="padding-top:0px;">
          <v-btn
            small
            color="green accent-3"
            @click="addUser()"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <v-col align="center" style="padding-top:0px;">
          <h2>Users</h2>
        </v-col>
        <v-col style="padding-top:0px;">
          <v-btn
            small
            color="cyan accent-3"
            @click="reloadUsers()"
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <CreateUser />
  </div>
</template>

<script>
import CreateUser from './create/CreateUser.vue'
export default {
  components : { CreateUser },
  methods : {
    addUser(){
      this.$store.commit('SET_MODAL_BASEUSER', {create:true});
    },
    reloadUsers(){
      this.$store.dispatch('LOAD_BASEUSERS', {create:true});
    }
  }
}
</script>

<style>

</style>