const numWords = require('../pdf/numWords');

let changeCalc = (card) =>{
  card.data.map(data=>{
    data.coef = card.coef
    if(data.overprice){
      data.card_amount = Number(data.coef) ? Number(data.coef)*Number(data.count) : 0*Number(data.count)
    } else {
      data.card_amount = 0*Number(data.count)
    }
    data.amount = data.card_amount + Number(data.net);
    data.amount_vat = data.amount * 1.21;
  })
};
let calcSumCardAmonut = (card) => {
  let sum = 0;
  card.data.map(data=>{
    sum += Number(data.card_amount)
  })
  return sum;
};
let calcSumAmonut = (card) => {
  let sum = 0;
  card.data.map(data=>{
    sum += Number(data.amount)
  })
  return sum;
};
let calcSumAmonutVAT = (card) => {
  let sum = 0;
  card.data.map(data=>{
    sum += Number(data.amount_vat)
  })
  return sum;
}
let calcSumAmonutNET = (card) => {
  let sum = 0;
  card.data.map(data=>{
    sum += Number(data.net)
  })
  return sum;
}
let calcSumAmonutDiscountPrice = (card) => {
  let sum = 0;
  card.data.map(data=>{
    sum += Number(data.discount_price)
  })
  return sum;
}
let calcSumAmonutDiscountVAT = (card) => {
  let sum = 0;
  card.data.map(data=>{
    sum += Number(data.price_after_discount_vat)
  })
  return sum;
}

let formation = (card, pdfCard, book) => {
  card.card_id = card.id;
  card.book_id = book.id;
  card.subcompany = book.subcompany
  card.paydate = formatDate(new Date(book.paydate));
  card.payup = formatDate(addDays(card.paydate, Number(card.payment_period)));
  pdfCard = JSON.parse(JSON.stringify(card))
  return pdfCard
}

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

let checker = (book) => {
  let errors = [];
  if(!book.paydate){ errors.push("Select Invoice Date") }
  if(!book.month){ errors.push("Select Invoice Month") }
  if(!book.subcompany){ errors.push("Select a Sub Company") }
  
  return errors;
}

let formationMail = (card, pdfCard, book, last_invoice) => {
  let temp = formation(card, pdfCard, book, last_invoice);
  temp.month = book.month;
  temp.preview = last_invoice.prefix + 
    (last_invoice.index.length>0 || last_invoice.count.length>0 ? last_invoice.split : '' ) + numWords.calcCount(last_invoice.index, last_invoice.count) + 
    (last_invoice.suffix.length>0 ? last_invoice.split : '' ) + last_invoice.suffix;
  temp.sum_vat = (calcSumAmonutVAT(card)).toFixed(2);
  temp.sum = (calcSumAmonut(card)).toFixed(2);
  temp.sum_en = numWords.numEN( temp.sum_vat );
  temp.sum_lt = numWords.numLT( temp.sum_vat );
  temp.number =  temp.preview,
  card = Object.assign({}, card, temp);
  return card
}

let changeOverprice = ( data ) => {
  if(data.overprice){
    data.card_amount = Number(data.coef) ? Number(data.coef)*Number(data.count) : 0*Number(data.count)
    data.amount = data.card_amount + Number(data.net);
    data.amount_vat = data.amount * 1.21;
  } else {
    data.card_amount = 0*Number(data.count)
    data.amount = data.card_amount + Number(data.net);
    data.amount_vat = data.amount * 1.21;
  }
}

module.exports = {
  changeCalc,
  calcSumCardAmonut, calcSumAmonut, calcSumAmonutVAT,
  formation,
  formatDate, addDays,
  checker,
  formationMail,
  changeOverprice,
  calcSumAmonutNET,
  calcSumAmonutDiscountPrice,
  calcSumAmonutDiscountVAT
}