export default
{
  state: {
    
  },
  mutations: {

  },
  actions:{
    async LOAD_MAIN_BOOK({commit, dispatch, getters }, payload){
      commit('SET_LOADING',{loading:true})
      await axios.post(getters.GET_SERVER+'/api/book/load/main',{
        id : payload.id
      })
      .then(function (response) {     
        response.data.book.month = Number(response.data.book.month)
        response.data.book.payment_period = Number(response.data.book.payment_period)
        let myCompanies = getters.GET_COMPANIES;
        myCompanies = myCompanies.filter(company => { 
          return company.subcompanyId === response.data.book.subcompany
        })
        response.data.cards.map(card=>{
          for(let i=0; i<myCompanies.length; i++){
            if(myCompanies[i].code == card.client_id && myCompanies[i].subcompanyId === response.data.book.subcompany){
              if(card.client_id == myCompanies[i].code){
                card.name = myCompanies[i].name;
                card.emails = myCompanies[i].emails;
                card.payment_period = myCompanies[i].payment_period;
                break;
              }
            }
            
          }
        })
        let preview=[];
        if(response.data.cards.length===0){ commit('SET_SAVEDPREVIEW', {savedPreview:false}) }
        else { 
          response.data.cards.map((company)=>{
            if(company.name){
              preview.push(company)
            }
          })
          commit('SET_SAVEDPREVIEW', {savedPreview:true}) 
        }
        commit('SET_LOADING',{loading:false})   
        commit('SET_BOOK', {book: response.data.book})
        commit('SET_PREVIEW', {preview: preview})
      })
      .catch(err=>{
        console.log(err)
      });
    },
    async SAVE_BOOK_MAIN({commit, dispatch, getters }, payload){
      await axios.post(getters.GET_SERVER+'/api/book/save/main',{
        book : getters.GET_BOOK,
        cards : getters.GET_PREVIEW
      })
      .then(function (response) {
        if(response.data.status){
          payload.vm.$toast.success({title:"Сохранено", message:"Главная"});
        }
      })
      .catch(err=>{
        payload.vm.$toast.error({title:err.response.data.message});
      });
    }, 
  },
  getters:{
    
  }
}