<template>
  <div>
    <v-row>
      <v-col>
        <v-text-field
          v-model="user.username"
          label="Login"
          @change="filter()"
          outlined dense hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    
  </div>
</template>

<script>
export default {
  name : 'Username',
  props : ['user'],
  methods : {
    filter(){
      if(this.user.username.includes('@aline')){
        this.user.username = this.user.username.replace('@aline', '');
      }
    }
  }
}
</script>

<style scope>
  .right-input input {
    text-align: right
  }
</style>