let months = [
  {id:1, title:'January'},
  {id:2, title:'February'},
  {id:3, title:'March'},
  {id:4, title:'April'},
  {id:5, title:'May'},
  {id:6, title:'June'},
  {id:7, title:'July'},
  {id:8, title:'August'},
  {id:9, title:'September'},
  {id:10, title:'October'},
  {id:11, title:'November'},
  {id:12, title:'December'},
];

module.exports = { months }