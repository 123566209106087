import Vue from 'vue';
import Vuex from 'vuex';

import general from '@/store/general/general';
import page from '@/store/page/page';
import colors from '@/store/colors/colors'
import bar from '@/store/bar/bar';

import baseUsers from '@/store/base/baseUsers';
import subcompanies from '@/store/base/subcompanies';
import numbering from '@/store/base/numbering';

import user from '@/store/user/user';
import companies from '@/store/companies/companies';

import books from '@/store/books/books';
import loadBooks from '@/store/books/loadBooks';
import downloads from '@/store/books/downloads';
import mail from '@/store/books/mail'

Vue.use(Vuex)

export default new Vuex.Store({
  modules:{
    general, page, colors, bar,
    baseUsers, subcompanies, numbering,
    user, companies,
    books, loadBooks, downloads, mail
  }
})
