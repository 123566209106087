export default
{
	state: {
    subcompany: {},
    modal_subcompany:{
      create : false,
      edit : false,
      delete : false,
    },
    subcompanies:[],
  },
  mutations: {
    SET_SUBCOMPANY(state,payload){
      state.subcompany=payload.subcompany;
    },
    UNSET_SUBCOMPANY(state){
      state.subcompany={};
    },
    SET_MODAL_SUBCOMPANY(state,payload){
      for(let i=0; i<Object.keys(payload).length; i++)
      {
        for(let j=0; j<Object.keys(state.modal_subcompany).length; j++)
        {
          if(Object.keys(payload)[i]==Object.keys(state.modal_subcompany)[j])
          {
            switch(Object.keys(state.modal_subcompany)[j])
            {
                case 'create': state.modal_subcompany.create=payload.create; break;
                case 'edit': state.modal_subcompany.edit=payload.edit; break;
                case 'delete': state.modal_subcompany.delete=payload.delete; break;
                default: console.log('Error');
            }
          }
        }
      }
    },
    UNSET_MODAL_SUBCOMPANY(state){
      state.modal_subcompany = {
        create : false,
        edit : false,
        delete : false,
      }
    },
    SET_SUBCOMPANIES(state,payload)
    {
      state.subcompanies=payload.subcompanies;
    },
    UNSET_SUBCOMPANIES(state)
    {
      state.subcompanies=[];
    },
  },
  actions: {
    async LOAD_BASE_SUBCOMPANIES({commit, dispatch, getters }, payload){
      await axios.post(getters.GET_SERVER+'/api/subcompanies/base', {})
      .then(response=> {
        if( response.data.status ){
          commit('SET_NUMBERINGS', {numberings:response.data.numberings});
        } else {
          this._vm.$toast.error({title:"Ошибка", message: response.data.message});
        }
      })
      .catch(err=>{
        this._vm.$toast.error({title:"Ошибка", message: err.message});
      });
    },
    async CREATE_SUBCOMPANY({commit, dispatch, getters }, payload){
      await axios.post(getters.GET_SERVER+'/api/subcompanies/create', {
        subCompany: getters.GET_SUBCOMPANY
      })
      .then(response=> {
        if( response.data.status ){
          commit('UNSET_SUBCOMPANY');
          commit('SET_MODAL_SUBCOMPANY',{create:false});
          dispatch('LOAD_SUBCOMPANIES');
        } else {
          this._vm.$toast.error({title:"Ошибка", message: response.data.message});
        }
      })
      .catch(err=>{
        this._vm.$toast.error({title:"Ошибка", message: err.message});
      });
    },
    async LOAD_SUBCOMPANIES({commit, dispatch, getters }, payload){
      await axios.get(getters.GET_SERVER+'/api/subcompanies/show?page='+getters.GET_SUBCOMPANIES_PAGINATION.page+'&per_page='+getters.GET_SUBCOMPANIES_PAGINATION.per_page)
      .then(function (response) {
        commit('SET_SUBCOMPANIES',{subcompanies:response.data.subcompanies});
        let pagination = getters.GET_SUBCOMPANIES_PAGINATION;
        pagination.total=response.data.total;
        commit('SET_SUBCOMPANIES_PAGINATION',{subcompanies_pagination:pagination});
      })
      .catch(err=>{
        this._vm.$toast.error({title:err.response.data.message});
      });
    },
    async EDIT_SUBCOMPANY({commit, dispatch, getters }, payload){
      await axios.post(getters.GET_SERVER+'/api/subcompanies/edit/'+payload.subcompany.id, {
        subCompany: payload.subcompany
      })
      .then(response=> {
        if( response.data.status ){
          commit('SET_MODAL_SUBCOMPANY',{edit:false});
          dispatch('LOAD_SUBCOMPANIES');
        } else {
          this._vm.$toast.error({title:"Ошибка", message: response.data.message});
        }
      })
      .catch(err=>{
        this._vm.$toast.error({title:"Ошибка", message: err.message});
      });
    },
    async DELETE_SUBCOMPANY({commit, dispatch, getters }, payload){
      await axios.post(getters.GET_SERVER+'/api/subcompanies/delete/'+payload.subcompany.id, {
        subCompany: payload.subcompany
      })
      .then(response=> {
        if( response.data.status ){
          commit('SET_MODAL_COMPANY',{delete:false})
          dispatch('LOAD_SUBCOMPANIES');
        } else {
          this._vm.$toast.error({title:"Ошибка", message: "Повторите запрос"});
        }
      })
      .catch(err=>{
        this._vm.$toast.error({title:"Ошибка", message: err.message});
      });
    },
    
  },
  getters:{
    GET_SUBCOMPANY: (state)=> state.subcompany,
    GET_MODAL_SUBCOMPANY: (state)=> state.modal_subcompany,
    GET_SUBCOMPANIES: (state)=> state.subcompanies,
  }
}