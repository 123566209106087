<template>
  <div>
    <v-text-field
      v-model="user.last_name"
      label="Last name"
      outlined dense hide-details
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name : 'LastName',
  props : ['user']
}
</script>

<style>

</style>