import Vue from 'vue';
import router from '../../router';

export default
{
	state: {
		user:{
			id:'',
      first_name:'',
      last_name:'',
      territory_id:'',
      role:'',  
      token:'',
		},
    authenticated:false,
    users:[],
  },
  mutations: {
    SET_USER(state,payload)
    {
      state.user=parser(payload.token);
      if(state.user)
      {
        state.authenticated=true;    
        if(!localStorage.getItem('token')) 
        localStorage.setItem('token', payload.token); 
        if(!axios.defaults.headers.common['x-auth-token']) 
        axios.defaults.headers.common['x-auth-token'] = payload.token;
      }
      else{
        localStorage.clear();
        axios.defaults.headers.common['x-auth-token'] = '';
      }
    },
    UNSET_USER(state){
      localStorage.clear();
      state.user={};
      state.authenticated=false;
      axios.defaults.headers.common['x-auth-token'] = '';
    },
    SET_USERS(state,payload)
    {
      state.users=payload.users;
    },
    UNSET_USERS(state)
    {
      state.users=[];
    },
    SET_AUTHENTICATED(state, payload){
      state.authenticated=payload.authenticated
    },
    UNSET_AUTHENTICATED(state, payload){
      state.authenticated=false
    }
  },
  actions: {
    async LOGIN({commit, dispatch, getters }, payload){
      await axios.post(getters.GET_SERVER+'/api/users/login', {
        username: payload.user.username,
        password: payload.user.password,
      })
      .then(function (response) {
        if(response.data.token)
        {
          commit('SET_USER',{token:response.data.token});
          let Decoded = parser(response.data.token);
          
          switch(Decoded.role)
          {
            case 'supervisor': 
              router.push({ path: '/books' }); 
              break;
            case 'admin': 
              router.push({ path: '/books' }); 
              break;
              case 'operator': 
              router.push({ path: '/books' }); 
              break;
            default: router.push({ path: 'login' });
          }
          payload.vm.$toast.success({title:'Welcome!', message:Decoded.first_name+' '+Decoded.last_name+'!'});
          dispatch('LOAD_NAVBAR_ITEMS');
        }
      })
      .catch(err=>{
        payload.vm.$toast.error({title:err.response.data.message});
      });
    },
    async LOGOUT({commit, dispatch, getters }, payload){
      commit('UNSET_NAVBAR_ITEMS');
      commit('UNSET_USER');
      commit('UNSET_USERS');
      commit('UNSET_AUTHENTICATED');
      axios.defaults.headers.common['x-auth-token']='';
      localStorage.removeItem('token');
      if (router.history.current.path != '/login') {
        router.push("/login");
      }
    },
  },
  getters:{
    GET_USER: (state)=> state.user,
    GET_USERS: (state)=> state.users,
    GET_AUTHENTICATED: (state) => state.authenticated,
  }
}

function parser(token) {
  function first(){
    try{
      let base64Url = token.split('.')[0];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);  
    }
    catch{
      localStorage.clear();
      return false;
    }
  }
  function second(){
    try{
      let base64Url = token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);  
    }
    catch{
      localStorage.clear();
      return false;
    }
  }
  return first() && second();
};