<template>
  <div>
    <v-dialog 
      max-width="1200"
      v-model="modal_company.create"
      scrollable
    >
      <v-card>
        <v-card-title 
          class="headline"
          style="background-color: #4caf50; color:white;"
        >
          Add Company
        </v-card-title>
        <v-card-text style="padding:0px;">
          <v-container>
            <v-row>
              <v-col>
                <Company :company="company" />
              </v-col>
            </v-row>
          </v-container>
            
          
        </v-card-text>
        
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            @click="modal_company.create=false"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="save_new_company()"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Company from '@/components/companies/layout/Company.vue'
export default {
  name : 'CreateComponent',
  props : ['company'],
  components : { Company },
  computed : {
    modal_company:{ 
      get:function(){ return this.$store.getters.GET_MODAL_COMPANY },
      set:function(value){ return this.$store.commit('SET_MODAL_COMPANY',{modal_company:value}) }
    },
  },
  methods:{
    save_new_company(){
      let errors = this.check_new_request();
      if( errors.length==0 ){
        this.$store.dispatch('CREATE_COMPANY').then(()=>{
          this.$store.commit('UNSET_COMPANY');
          this.$store.commit('SET_MODAL_COMPANY',{create:false})
          this.$emit('create', { subcompany: this.company.subcompanyId,})
        });
      } else {
        for(let i=0; i<errors.length; i++){
          this.$toast.error({title:"Создание Компании", message: errors[i]})
        }
      }
    },
    check_new_request(){
      let errors = [];
      // if(!this.company.type && this.company.type!=0){ errors.push("Тип пусто!") }
      if(!this.company.name){ errors.push("Наименование пусто!") }
      // if(!this.company.address){ errors.push("Адрес пусто!") }
      // if(!this.company.tin){ errors.push("ИНН пусто!") }
      // if(!this.company.requisites){ errors.push("Реквизиты пусто!") }
      // if(!this.company.comment){ errors.push("Комментарии пусто!") }
      // if(!this.company.director){ errors.push("Директор пусто!") }
      return errors;
    }
  }
}
</script>

<style>

</style>