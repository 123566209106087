<template>
  <div>

    <v-row v-if="!savedPreview" class="text-center">
      <v-col>
        <v-row>
          <v-col>
            Save to send an email
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col>
            Išsaugoti siųsti el. laišką
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-if="savedPreview">
      <v-col>
        <v-btn
          dark
          block
          :loading="loadingMail"
          color="deep-orange darken-2"
          @click="sendAll()"
        >
          Send to everyone
          <v-icon>
            mdi-email-fast
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="savedPreview">
      <v-col>
        <v-row>
          <v-col align="center">
            Not Sent <span :class="statuses.notSent>=0 ? 'numberCircle' : '' " :style="statuses.notSent>=0 ? 'border: 1px solid black; color: black;' : ''">{{statuses.notSent}}</span>
          </v-col>
          <v-col align="center">
            Success <span :class="statuses.success>=0 ? 'numberCircle' : '' " :style="statuses.success>=0 ? 'border: 1px solid green; color: green;' : ''">{{statuses.success}}</span>
          </v-col>
          <v-col align="center">
            Fail <span :class="statuses.fail>=0 ? 'numberCircle' : '' " :style="statuses.fail>=0 ? 'border: 1px solid red; color: red;' : ''">{{statuses.fail}}</span>
          </v-col>
        </v-row>
        
        
      </v-col>
    </v-row>
  </div>
</template>

<script>
import calc from './card/calc'
import mail from './mail'
import numWords from './pdf/numWords';

export default {
  props : ['book'],
  data: () => ({
    card:{},
    pdfCard : {},
    statuses : {
      notSent : null,
      success : null,
      fail : null
    }
  }),
  watch : {
    preview:{
      deep:true,
      handler(){
        this.changeMail()
      }
      
    }
  },
  computed : {
    preview : { 
      get:function(){ return this.$store.getters.GET_PREVIEW },
      set:function(value){ return this.$store.commit('SET_PREVIEW',{preview:value}) }
    },
    savedPreview : { 
      get:function(){ return this.$store.getters.GET_SAVEDPREVIEW },
      set:function(value){ return this.$store.commit('SET_SAVEDPREVIEW',{savedPreview:value}) }
    },
    last_invoice : {
      get:function(){ return this.$store.getters.GET_LAST_INVOICE },
      set:function(value){ return this.$store.commit('SET_LAST_INVOICE',{last_invoice:value}) }
    },
    loadingMail : {
      get:function(){ return this.$store.getters.GET_LOADINGMAIL },
      set:function(value){ return this.$store.commit('SET_LOADINGMAIL',{loadingMail:value}) }
    }
  },
  methods : {
    async sendAll(){
      let errors = calc.checker(this.book);
      if(this.preview.length===0){errors.push("Cards is empty!")}
      if(errors.length ==0 ){
        for (const company of this.preview) {
          if(!company.send){
            if(company.invoice){
              this.card = JSON.parse(JSON.stringify(company))
              let temp = calc.formation(this.card, this.pdfCard, this.$store.getters.GET_BOOK);
              temp.month = this.$store.getters.GET_BOOK.month;
              temp.number = this.card.invoice.number;
              temp.sum_vat = (calc.calcSumAmonutVAT(this.card)).toFixed(2);
              temp.sum = (calc.calcSumAmonut(this.card)).toFixed(2);
              temp.sum_en = numWords.numEN( temp.sum_vat );
              temp.sum_lt = numWords.numLT( temp.sum_vat );
              this.card = JSON.parse(JSON.stringify(temp))
              await this.$store.dispatch('SEND_MAIL_ONE', {card: this.card, vm:this, id: company.id})
            } else { this.$toast.warn({title:"Error",message:"There is no invoice on the card"})}
          } else { 
            this.$toast.warn({title:"Warning",message:"Mail was sent to recipient!"})
          }
        }
      } else {
        errors.map(err=>{
          this.$toast.error({title:"Error", message:err})
        })
      }
    },
    changeMail(){
      this.statuses = mail.calcMails(this.preview, this.statuses)
    }
  }
}
</script>

<style>
.numberCircle {
    border-radius: 50%;
    width: 30px;
    height: 24px;
    padding: 4px;

    background: #fff;
    text-align: center;

    font: 20px Arial, sans-serif;
}
</style>