<template>
  <div>
    <v-row>
      <v-col class="pt-6">
        <v-text-field
          v-model="company.payment_period"
          label="Payment period"
          dense outlined hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    
  </div>
</template>

<script>
export default {
  props: ['company']
}
</script>

<style>

</style>