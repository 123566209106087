export default
{
  state: {
    server: process.env.VUE_APP_SERVER
  },
  mutations: {

  },
  actions:{

  },
  getters:{
    GET_SERVER:(state)=>state.server,
  }
}