<template>
  <div>
    <v-row>
      <v-col style="padding:0px;">
        <Pagination :pagination="baseUsers_pagination" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Pagination from '@/components/layouts/pagination/PaginationComponent.vue'
export default {
  name : 'BaseUsersPagination',
  components:{
    Pagination
  },
  computed:{
    baseUsers_pagination:{ 
      get:function(){ return this.$store.getters.GET_BASEUSERS_PAGINATION },
      set:function(value){ return this.$store.commit('SET_BASEUSERS_PAGINATION',{baseUsers_pagination:value}) }
    },
  },
  watch:{
    baseUsers_pagination:{
      handler(){
        this.$store.dispatch('LOAD_BASEUSERS');
      },
      deep:true
    }
  },
}
</script>

<style>

</style>