<template>
  <div>
    <v-row>
      <v-col>
        <Names :company="subcompany" />        
      </v-col>
      <v-divider vertical></v-divider>
      <v-col>
        <Address :company="subcompany" />
      </v-col>
    </v-row>
    <v-row>
      <v-col style="padding:0px;">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <Email :company="subcompany" />
          </v-col>
        </v-row>
        <v-row>
          <v-col style="padding:0px;">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <Numbering :company="subcompany" />
          </v-col>
        </v-row>
        
      </v-col>
      <v-divider vertical></v-divider>
      <v-col style="padding-top:0px;">
        <Requisites :company="subcompany" style="margin-top : 12px;" />
      </v-col>
    </v-row>
    <v-row>
      <v-col style="padding:0px;">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <Comment :company="subcompany" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Address from '@/components/companies/layout/Address.vue'
import Contact from '@/components/companies/layout/Contact.vue'
import Names from '@/components/companies/layout/Names.vue'
import Comment from '@/components/companies/layout/Comment.vue'
import Requisites from '@/components/companies/layout/Requisites.vue'
import Email from './Email.vue'
import Numbering from './Numbering.vue'

export default {
  name : 'SubCompany',
  props : ['subcompany'],
  components: { Names, Address, Contact, Comment, Requisites, Email, Numbering},
}
</script>

<style>

</style>