<template>
  <div>
    <v-dialog 
      max-width="1200"
      v-model="modal_company.delete"
      scrollable
    >
      <v-card>
        <v-card-title 
          class="headline"
          style="background-color: #ff5252; color:white;"
        >
          Delete Company ID: {{company.id}}
        </v-card-title>
        <v-card-text style="padding:0px;">
          <v-container>
            <v-row>
              <v-col>
                Delete Company {{company.name}} ?
              </v-col>
            </v-row>
          </v-container>
            
          
        </v-card-text>
        
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            @click="modal_company.delete=false"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="save_delete_request()"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name : 'DeleteComponent',
  props : ['company'],
  computed : {
    modal_company:{ 
      get:function(){ return this.$store.getters.GET_MODAL_COMPANY },
      set:function(value){ return this.$store.commit('SET_MODAL_COMPANY',{modal_company:value}) }
    },
  },
  methods:{
    save_delete_request(){
      this.$store.dispatch('DELETE_COMPANY', {company:this.company}).then(()=>{
        this.$store.commit('SET_MODAL_COMPANY',{delete:false})
        this.$store.dispatch('LOAD_COMPANIES', {subcompany: this.company.subcompanyId});
      });
    }
  }
}
</script>

<style>

</style>