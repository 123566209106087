<template>
  <div>
    Cards Table
  </div>
</template>

<script>
export default {
  props:['company'],
  watch:{
    company(){
      this.loadCards();
    }
  },
  mounted(){
    this.loadCards();
  },
  methods:{
    loadCards(){
      console.log(this.company.id)
    }
  }
}
</script>

<style>

</style>