<template>
  <div>
    <v-row>
      <v-col>
        <FileInput :book="book" />
      </v-col>
    </v-row>
    <v-divider style="margin: 12px 0px 12px 0px; padding-left:-12px;" ></v-divider>
    <v-row>
      <v-col>
        <ResultTable v-if="!loading" :book="book"/>
        <div v-if="loading">Loading...</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <Comment :book="book" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FileInput from './main/FileInput.vue';
import ResultTable from './main/ResultTable.vue';
import Comment from './main/Comment.vue';

export default {
  components : { ResultTable, Comment, FileInput },
  data: ()=>({
    
  }),
  computed : {
    book : { 
      get:function(){ return this.$store.getters.GET_BOOK },
      set:function(value){ return this.$store.commit('SET_BOOK',{book:value}) }
    },
    loading:{ 
      get:function(){ return this.$store.getters.GET_LOADING },
      set:function(value){ return this.$store.commit('SET_LOADING',{loading:value}) }
    },
  },
  mounted(){
    this.loadBook();
  },
  methods : {
    loadBook(){
      this.$store.dispatch('LOAD_BASE_BOOK',{vm:this}).then(()=>{
        this.$store.dispatch('LOAD_MAIN_BOOK',{id: this.$route.params.id});
      }) 
    },
  }
  
  
}
</script>

<style>

</style>