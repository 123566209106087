<template>
  <div>
    <Widgets />
    <BooksTable />
    <BooksPagination />
  </div>
</template>

<script>
import Widgets from './Widgets.vue';
import BooksTable from './table/BooksTable.vue';
import BooksPagination from './pagination/BooksPagination.vue';
export default {
  name : 'BooksComponent',
  components : { Widgets, BooksTable, BooksPagination},
  mounted(){
    this.loadBase();
  },
  methods:{
    loadBase(){
      this.$store.dispatch('LOAD_BASE_BOOK').then(()=>{
        this.$store.dispatch('LOAD_BOOKS');
      });
    },
  }
}
</script>

<style>

</style>