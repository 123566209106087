<template>
  <div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr :style="{'background-color': colors.table}">
            <th class="text-left" style="color:white;">
              #
            </th>
            <th class="text-left" style="color:white;">
              Clinet
            </th>
            <th class="text-left" style="color:white;">
              C Type
            </th>
            <th class="text-left" style="color:white;">
              Payup
            </th>
            <th class="text-left" style="color:white;">
              Email
            </th>
            <th class="text-left" style="color:white;">
              Coefficient, %
            </th>
            <th class="text-left ell" style="color:white;">
              KORTELĖS MOKESTIS
            </th>
            <th class="text-left ell" style="color:white;">
              Mokėtina suma be PVM
            </th>
            <th class="text-left ell" style="color:white;">
              Mokėtina suma su PVM
            </th>
            <th class="text-left" style="color:white;">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item,index) in preview" :key="index"
            :class="(item.send) ? 'sended' : (item.send)===0 ? 'failsend' : ''"
          >
            <td class="brdr" style="max-width:50px;">
              {{index+1}}
            </td>
            <td class=" ell brdr" style="max-width:200px;">
              {{item.name}}
            </td>
            <td class=" ell brdr" style="max-width:200px;">
              {{item.client_id}} | {{item.client_id.includes('A') ? 'FUNN' : 'Circle K'}}
            </td>
            <td class=" ell brdr" style="max-width:100px;">
              <v-text-field
                v-model="item.payment_period"
                outlined dense hide-details
              ></v-text-field>
            </td>
            <td class=" ell brdr" style="max-width:200px;">
              {{item.emails}}
            </td>
            <td class="brdr pa-0" style="max-width:50px;">
              <!-- {{getCoef(item.client_id)}} -->
              <v-text-field
                v-model="item.coef"
                outlined dense hide-details
              ></v-text-field>
            </td>
            <td class="brdr pa-0 text-end pr-3" style="width:100px; max-width:100px;">
              {{ Number( (calc.calcSumCardAmonut(item)).toFixed(2) ).toLocaleString() }}
            </td>
            <td class="brdr pa-0 text-end pr-3" style="width:100px; max-width:100px;">
              {{ Number( (calc.calcSumAmonut(item)).toFixed(2) ).toLocaleString() }}
            </td>
            <td class="brdr pa-0 text-end pr-3" style="width:100px; max-width:100px;">
              {{ Number( (calc.calcSumAmonutVAT(item)).toFixed(2) ).toLocaleString() }}
            </td>
            <td class="ell brdr pl-0 pr-0" style="min-width:200px; width:200px; max-width:200px;">
              <v-row class="pa-0 ma-0">
                <v-col class="pa-0 ma-0" align="center">
                  <v-btn
                    class="ma-1"
                    color="primary"
                    small
                    icon
                    @click="edit_card(index,item.client_id)"
                  >
                    <v-icon>mdi-open-in-new</v-icon>
                  </v-btn>
                  <v-btn
                    class="ma-1"
                    color="success"
                    small
                    icon
                    @click="excel_card(index,item.client_id)"
                  >
                    <v-icon>mdi-microsoft-excel</v-icon>
                  </v-btn>
                  <v-btn
                    class="ma-1"
                    color="warning"
                    small
                    icon
                    v-if="savedPreview && item.invoice"
                    @click="pdf_card(index,item.client_id)"
                  >
                    <v-icon>mdi-file-pdf-box</v-icon>
                  </v-btn>
                  <v-btn
                    class="ma-1"
                    color="deep-orange darken-2"
                    small
                    :loading="loadingMail"
                    icon
                    v-if="savedPreview && item.invoice"
                    @click="sendMail_card(index,item.client_id)"
                  >
                    <v-icon>mdi-email-fast</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr :style="{'background-color': colors.table}">
            <td colspan="100"></td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
    <Total :cards="preview" :book="book"/>
    <Card :card="card" :index="index"/>
    <PDF :card="pdfCard"/>
  </div>
</template>

<script>
import PDF from './pdf/PDFModal.vue';
import Card from './card/Card.vue';
import numWords from './pdf/numWords';
import calc from './card/calc';
import Total from './total/Total.vue'

export default {
  components : { Card, PDF, Total },
  data : ()=>({
    card:{},
    pdfCard : {},
    index: {
      index : null,
      type : 1
    },
  }),
  computed : {
    calc : ()=>{
      return calc;
    },
    book : { 
      get:function(){ return this.$store.getters.GET_BOOK },
      set:function(value){ return this.$store.commit('SET_BOOK',{book:value}) }
    },
    preview : { 
      get:function(){ return this.$store.getters.GET_PREVIEW },
      set:function(value){ return this.$store.commit('SET_PREVIEW',{preview:value}) }
    },
    savedPreview : { 
      get:function(){ return this.$store.getters.GET_SAVEDPREVIEW },
      set:function(value){ return this.$store.commit('SET_SAVEDPREVIEW',{savedPreview:value}) }
    },
    modal_book : { 
      get:function(){ return this.$store.getters.GET_MODAL_BOOK },
      set:function(value){ return this.$store.commit('SET_MODAL_BOOK',{modal_book:value}) }
    },
    companies : {
      get:function(){ return this.$store.getters.GET_COMPANIES },
      set:function(value){ return this.$store.commit('SET_COMPANIES',{companies:value}) }
    },
    colors:{ 
      get:function(){ return this.$store.getters.GET_COLORS }
    },
    last_invoice : {
      get:function(){ return this.$store.getters.GET_LAST_INVOICE },
      set:function(value){ return this.$store.commit('SET_LAST_INVOICE',{last_invoice:value}) }
    },
    loadingMail : {
      get:function(){ return this.$store.getters.GET_LOADINGMAIL },
      set:function(value){ return this.$store.commit('SET_LOADINGMAIL',{loadingMail:value}) }
    }
  },
  mounted(){
    
  },
  methods : {
    
    getCoef(id){
      for(let i=0; i<this.companies.length; i++){
        if(this.companies[i].code == id){
          return this.companies[i].coef;
          break;
        }
      }
    },
    edit_card(index, id){      
      if(this.preview[index].client_id == id){
        this.card = JSON.parse(JSON.stringify(this.preview[index]));
        this.index.index=index;
        this.index.type = this.book.type;
        this.modal_book.edit = true;
      }
    },
    excel_card(index,id){
      this.$store.dispatch("EXCEL_CARD_DOWN",{vm:this, card:JSON.parse(JSON.stringify(this.preview[index]))})
    },
    pdf_card(index,id){
      let errors = calc.checker(this.book);
      if(errors.length ==0 ){
        this.card = JSON.parse(JSON.stringify(this.preview[index]))
        let temp = calc.formation(this.card, this.pdfCard, this.book, this.last_invoice);
        temp.month = this.$store.getters.GET_BOOK.month;
        temp.preview = this.card.invoice.number;
        temp.sum_vat = (calc.calcSumAmonutVAT(this.card)).toFixed(2);
        temp.sum = (calc.calcSumAmonut(this.card)).toFixed(4);
        temp.sum_en = numWords.numEN( Number(temp.sum_vat).toFixed(2) );
        temp.sum_lt = numWords.numLT( Number(temp.sum_vat).toFixed(2) );
        this.pdfCard = JSON.parse(JSON.stringify(temp))
        this.modal_book.pdf = true;
      } else {
        errors.map(err=>{
          this.$toast.error({title:"Error", message:err})
        })
      }
      
      
    },
    sendMail_card(index, id){

      let errors = calc.checker(this.book);
      if(errors.length ==0 ){
        if(this.preview[index].client_id == id){
          this.card = JSON.parse(JSON.stringify(this.preview[index]))
          let temp = calc.formation(this.card, this.pdfCard, this.$store.getters.GET_BOOK);
          temp.month = this.$store.getters.GET_BOOK.month;
          temp.number = this.card.invoice.number;
          temp.sum_vat = (calc.calcSumAmonutVAT(this.card)).toFixed(2);
          temp.sum = (calc.calcSumAmonut(this.card)).toFixed(2);
          temp.sum_en = numWords.numEN( temp.sum_vat );
          temp.sum_lt = numWords.numLT( temp.sum_vat );
          this.card = JSON.parse(JSON.stringify(temp))
          this.$store.dispatch('SEND_MAIL_ONE', {card: this.card, vm:this, id: this.preview[index].id})
        }
        if(this.preview[index].send){ this.$toast.warn({title:"Warning",message:"Mail was sent to recipient!"}) }
      } else {
        errors.map(err=>{
          this.$toast.error({title:"Error", message:err})
        })
      }
    }
  }
}
</script>

<style>
.sended{
  background-color: lightgreen;
}
.failsend{
  background-color: coral;
}
</style>