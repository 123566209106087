<template>
  <div>
    <v-row>
      <v-col>
        <v-text-field
          v-model="card.coef"
          label="Coefficient, %"
          dense outlined hide-details
        ></v-text-field>
      </v-col>
      <v-col align="end">
        <v-row>
          <v-col>
            <v-btn
              color="success"
              small
              icon
              @click="excel_card()"
            >
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              color="warning"
              small
              icon
              v-if="savedPreview"
              @click="pdf_card()"
            >
              <v-icon>mdi-file-pdf-box</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <PDFModal :card="pdfCard"/>
  </div>
</template>

<script>
import PDFModal from './pdf/PDFModal.vue';
import numWords from '../pdf/numWords';
import calc from './calc';
export default {
  props : ['card'],
  components : { PDFModal },
  data: ()=>({
    pdfCard : {}
  }),
  computed : {
    modal_book : { 
      get:function(){ return this.$store.getters.GET_MODAL_BOOK },
      set:function(value){ return this.$store.commit('SET_MODAL_BOOK',{modal_book:value}) }
    },
    last_invoice : {
      get:function(){ return this.$store.getters.GET_LAST_INVOICE },
      set:function(value){ return this.$store.commit('SET_LAST_INVOICE',{last_invoice:value}) }
    },
    savedPreview : { 
      get:function(){ return this.$store.getters.GET_SAVEDPREVIEW },
      set:function(value){ return this.$store.commit('SET_SAVEDPREVIEW',{savedPreview:value}) }
    },
  },
  methods:{
    excel_card(){
      this.$store.dispatch("EXCEL_CARD_DOWN",{vm:this, card:this.card})
    },
    pdf_card(){ // перед этим нужно subcompany
    if(this.$store.getters.GET_BOOK.subcompany){
        let temp = calc.formation(this.card, this.pdfCard, this.$store.getters.GET_BOOK);
        temp.month = this.$store.getters.GET_BOOK.month;
        temp.preview = this.card.invoice.number;
        temp.sum_vat = (calc.calcSumAmonutVAT(this.card)).toFixed(2);
        temp.sum = (calc.calcSumAmonut(this.card)).toFixed(4);
        temp.sum_en = numWords.numEN( Number(temp.sum_vat).toFixed(2) );
        temp.sum_lt = numWords.numLT( Number(temp.sum_vat).toFixed(2) );
        this.pdfCard = JSON.parse(JSON.stringify(temp))
        this.modal_book.pdfWidgets = true;
      } else {
        this.$toast.error({title:"Error",message:"Select a Sub Company"})
      }
    },
  }
}
</script>

<style>

</style>