<template>
  <div>
    <v-textarea
      v-model="numbering.comment"
      rows="3"
      label="Comment"
      outlined dense hide-details
    ></v-textarea>
  </div>
</template>

<script>
export default {
  props : ['numbering']
}
</script>

<style>

</style>