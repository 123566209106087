<template>
  <div>
    <v-row>
      <v-col style="padding-bottom:0px;">
        <CompaniesTable :companies="companies" :subcompany="subcompany" />
      </v-col>
    </v-row>
    <v-row>
      <v-col style="padding:0px;">
        <Pagination :pagination="companies_pagination" @load="loadCompanies()" />
      </v-col>
    </v-row>
    
  </div>
</template>

<script>
import CompaniesTable from './table/CompaniesTable.vue'
import Pagination from '@/components/layouts/pagination/PaginationComponent.vue'
export default {
  name : 'Companies',
  props: [ 'subcompany' ],
  components:{
    CompaniesTable, Pagination
  },
  computed:{
    companies:{ 
      get:function(){ return this.$store.getters.GET_COMPANIES },
      set:function(value){ return this.$store.commit('SET_COMPANIES',{companies:value}) }
    },
    companies_pagination:{ 
      get:function(){ return this.$store.getters.GET_COMPANIES_PAGINATION },
      set:function(value){ return this.$store.commit('SET_COMPANIES_PAGINATION',{companies_pagination:value}) }
    },
  },
  mounted(){
    this.loadCompanies();
  },
  methods:{
    loadCompanies(){
      this.$store.dispatch('LOAD_COMPANIES', {subcompany: this.subcompany});
    }
  }
}
</script>

<style>

</style>