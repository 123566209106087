<template>
  <div>
    <v-select
      v-model="company.numbering_id"
      :items="numberings"
      item-text="name"
      item-value="id"
      label="Numbering"
      dense outlined hide-details
    ></v-select>
  </div>
</template>

<script>
export default {
  props : ['company'],
  computed : {
    numberings : { 
      get:function(){ return this.$store.getters.GET_NUMBERINGS },
      set:function(value){ return this.$store.commit('SET_NUMBERINGS',{numberings:value}) }
    },
  }
}
</script>

<style>

</style>