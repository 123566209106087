<template>
  <div>
    <Widgets />
    <Table />
    <Pagination />
  </div>
</template>

<script>
import Widgets from './Widgets.vue';
import Table from './table/SubCompaniesTable.vue'
import Pagination from './pagination/SubCompaniesPagination.vue'
export default {
  components: { Widgets, Table, Pagination },
  name : 'SubCompanies',
  mounted(){
    this.loadBaseNumbering()
  },
  methods:{
    loadBaseNumbering(){
      this.$store.dispatch('LOAD_BASE_SUBCOMPANIES')
    }
  }
}
</script>

<style>

</style>