<template>
  <div>
    <br/>
    <div class="text-center">
      <v-row>
        <v-col></v-col>
        <v-col>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                dense hide-details
                v-model="pagination.page"
                @change="next()"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-pagination
                v-model="pagination.page"
                :length="pagination.total ? pagination.total : 10"
                :total-visible="pagination.visible ? pagination.visible : 10"
                circle
                @input="next()"
              ></v-pagination>
            </v-col>
            <v-col>
              <v-text-field
                v-model="pagination.per_page"
                outlined
                dense hide-details
                @change="next()"
              ></v-text-field>
            </v-col>
          </v-row>   
          
        </v-col>
        <v-col></v-col>
      </v-row>
      
    </div>
  </div>
</template>

<script>
export default {
  name:'PaginationComponent',
  props:['pagination'],
  methods:{
    next(){
      this.pagination.page = Number(this.pagination.page);
      this.pagination.per_page = Number(this.pagination.per_page);
      this.$emit('load');
    }
  }
  
}
</script>

<style>

</style>