export default
{
  state: {
    loadingMail:false
  },
  mutations: {
    SET_LOADINGMAIL(state,payload){ state.loadingMail=payload.loadingMail; },
    UNSET_LOADINGMAIL(state){ state.loadingMail=[]; },
  },
  actions:{
    async SEND_MAIL_ONE({commit, dispatch, getters }, payload){
      commit('SET_LOADINGMAIL', {loadingMail:true})
      await axios.post(getters.GET_SERVER+'/api/mail/sendOne',{
        card : payload.card,
        type : getters.GET_BOOK.type
      })
      .then(function (response) {    
        commit('SET_LOADINGMAIL', {loadingMail:false}) 
        if(response.data.status){
          payload.vm.$toast.success({title:"Success", message:"Mail sent"});
          commit('EDIT_PREVIEW', {id:payload.id, send:true})
        } else {
          payload.card.send = false;
          payload.vm.$toast.error({title:"Error", message:response.data.message});
          commit('EDIT_PREVIEW', {id:payload.id, send:false})
        }
      })
      .catch(err=>{
        console.log(err)
      });
    },
  },
  getters:{
    GET_LOADINGMAIL:(state)=>state.loadingMail,
  }
}